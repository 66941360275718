import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import imgApp from "../../../assets/actu/actu_sucre.jpg";
import "./actualites.scss";

const AppInfo = () => {
  return (
    <Container className="py-10">
     
          <img src={imgApp} className="photo-news" alt="img-app" />
   
          <h2 className="title p-4 text-center">Ajoutez des sucres à la ration pour favoriser la production</h2>
          <p className="text mb-5">
          Les ruminants (petits et grands) ont un système digestif bien caractéristique, dont l’équilibre du rumen est primordial , tant pour le bien être des animaux, que pour les besoins de production attendue (croissance, lait, viande)
          De façon générale, le rationnement de ces animaux est basé sur les critères principaux suivants :
          -Taux de MS de la ration
          -Quantité MS ingérée
          -Valeur énergétique (concentration UF/kg MS ingérée)
          -Valeur MAT (% protéines dans la ration)
          Et dans un second temps, avec plus de détail sur :
          -valeur amidon (et le type d’amidon)
          -Taux de Cellulose Brute (et ADF, NDF…)
          -Le pourcentage de Matières Grasses
          -Les sources de protéines (solubles/ protégées) mesurées notamment avec équilibre PDIN / PDIE / PDIA
          -Et bien sûr le pourcentage de sucres, lequel est très important, car les micro-organismes du rumen ont besoin d’un minimum de sucres pour se nourrir, de façon à optimiser la ration distribuée
          On conseil alors un objectif optimal entre 4 et 5 % de sucres, seuil qui la plupart du temps est inférieur, si l’on ne rapporte pas de sucres supplémentaires !
          Rappelons que l’apport de sucres nous amène à une amélioration de l’ingestion et de la digestibilité de la ration supérieure à 5% , et donc une augmentation de la production !

          </p>
     
   
    </Container>
  );
};

export default AppInfo;
