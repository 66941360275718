import React from "react";
import { Container, Carousel } from "react-bootstrap";
import "./vache-lait.css";
import { Helmet } from "react-helmet";
import NavNutri from "../../../navNutrition";

const VacheLait = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - Les vaches laitières" />
        <meta
          property="og:description"
          content="L’utilisation de l’aliment complémentaire liquide dans les rations des vaches laitières est devenu quasiment incontournable, si l’on se réfère aux réels besoins de celles-ci, quelque soit la race, le stade de lactation et la production de chacune."
        />
      </Helmet>
      <Carousel>
        <Carousel.Item>
          <img
            className="w-100 carouselVacheLait"
            src={require("../../../../assets/vaches/Nutrition_Laitières_2.jpg")}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100 carouselVacheLait"
            src={require("../../../../assets/vaches/Nutrition_Laitières_4.jpg")}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100 carouselVacheLait"
            src={require("../../../../assets/vaches/Nutrition_Laitières_3.jpg")}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100 carouselVacheLait"
            src={require("../../../../assets/vaches/Home_Vachesnoires.jpg")}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
      <Container className="py-5">

      <NavNutri eventKey="laitieres" />


        <h2 className="title mb-5">Les vaches laitières</h2>
        <p className="text fw-bold">
          L’utilisation de l’aliment complémentaire liquide dans les rations des
          vaches laitières est devenu quasiment incontournable, si l’on se
          réfère aux réels besoins de celles-ci, quelque soit la race, le stade
          de lactation et la production de chacune.
        </p>
        <p className="text">
          Toutes nos formules d’aliment liquide sont appropriées pour tout type
          de ration, en fonction des fourrages utilisés, mais aussi, à chaque
          niveau de stade de lactation.
        </p>
        <p className="text">
          Les besoins en énergie des vaches laitières en début de lactation sont
          relativement importants, mais les apports sont souvent limités,
          notamment par une plus faible capacité d’ingestion intervenant après
          vêlage. C’est ici qu’intervient l’intérêt, entre autres, des aliments
          complémentaires liquides, de par leur encombrement nul, apportant
          jusqu’à 1 UFL/Kg de MS et 350 g de sucre/Kg brut sur les 1000g dont la
          vache a besoin quotidiennement.
        </p>
        <p className="text">
          L’aliment complémentaire liquide, apportant sucres et azote soluble,
          de façon simultanée, intervient également sur l’augmentation de
          l’ingestion globale, de l’ordre de 5 à 8 %, de par son appétence, et
          donc par conséquent, l’augmentation de la production laitière, de la
          quantité de Matière Utile produite.
        </p>
        <p className="text">
          N’hésitez pas à contacter votre conseiller terrain <b>Talian</b> ,
          afin de déterminer ensemble la formule d’Aliment Complémentaire
          Liquide adaptée qui correspond à vos besoins, en fonction de votre
          ration et de vos objectifs.
        </p>
      </Container>
    </>
  );
};

export default VacheLait;
