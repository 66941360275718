import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Contact from "../pages/contact";
import Accueil from "../pages/Accueil";
import Layout from "../layout";
import Legal from "../pages/legal";
import History from "../pages/company/history";
import Teams from "../pages/company/teams";
import Production from "../pages/company/production";
import Matieres from "../pages/company/matieres";
import Qualite from "../pages/company/qualite";
import Services from "../pages/company/services";
import Actualites from "../pages/actualites";
import Nutrition from "../pages/nutrition";
import VacheLait from "../pages/nutrition/vache-lait";
import VacheAllait from "../pages/nutrition/vache-allait";
import NotFound from "../pages/404";
import Engraissement from "../pages/nutrition/engraissement";
import Ovins from "../pages/nutrition/ovins";
import Livraisons from "../pages/livraisons";
import FAQ from "../pages/FAQ";
import Protalcanne from "../pages/products/protalcanne";
import Gamme from "../pages/products/gamme";
import Materiel from "../pages/products/materiel";
import Temoignages from "../pages/products/temoignage";
import AppInfo from "../pages/actualites/app-info";
import AddSucre from "../pages/actualites/20220916";
import OAD from "../pages/oad";
import Administration from "../pages/administration";
import parseJwt from "../../helps/parseJwt";
import { loadFromLocalStorage } from "../../helps/localStorage";

function App() {
  const [user, setUser] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const user = loadFromLocalStorage("token")
    if(user){
      setUser(user)
    }
  }, [])

  useEffect(() => {
    (async() => {
      if(user){
        const token = loadFromLocalStorage("token")
        const parsedToken = parseJwt(token)
        if(parsedToken?.isAdmin){
          setIsAdmin(true)
        }
        else{
          setIsAdmin(false)
        }
      }
      else{
        setIsAdmin(false)
      }
    })()
  }, [user])

  return (
    <Layout isAdmin={isAdmin} user={user} setUser={setUser}>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route exact path="/" element={<Accueil />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/company/history" element={<History />} />
        <Route path="/company/teams" element={<Teams />} />
        <Route path="/company/production" element={<Production />} />
        <Route path="/company/matieres" element={<Matieres />} />
        <Route path="/company/qualite" element={<Qualite />} />
        <Route path="/company/services" element={<Services />} />
        <Route path="/actualites" element={<Actualites />} />
        <Route path="/actualites/app-info" element={<AppInfo />} />
        <Route path="/actualites/20220916" element={<AddSucre />} />
        <Route path="/nutrition" element={<Nutrition />} />
        <Route path="/nutrition/vaches-laitieres" element={<VacheLait />} />
        <Route path="/nutrition/vaches-allaitantes" element={<VacheAllait />} />
        <Route path="/nutrition/engraissement" element={<Engraissement />} />
        <Route path="/nutrition/ovins" element={<Ovins />} />
        <Route path="/livraisons" element={<Livraisons />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/products/protalcanne" element={<Protalcanne />} />
        <Route path="/products/gamme" element={<Gamme />} />
        <Route path="/products/materiel" element={<Materiel />} />
        <Route path="/products/temoignages" element={<Temoignages />} />
        <Route path="/oad" element={<OAD />} />
        {isAdmin && (
          <Route path="/admin" element={<Administration />} />
        )}
      </Routes>
    </Layout>
  );
}

export default App;
