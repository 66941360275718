import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { saveToLocalStorage } from '../../../helps/localStorage'
import validateEmail from '../../../helps/validateEmail'
import ReCAPTCHA from "react-google-recaptcha";
import "./modal.scss"

export default function LoginModal({show, setShow, setUser}) {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const recaptchaRef = useRef(null);

  useEffect(() => {
    setErrorMessage("")
  }, [email, password])

  useEffect(() => {
    setEmail("")
    setPassword("")
    setErrorMessage("")
  }, [show])
  
  const authenticate = async() => {
    setErrorMessage("")
    if(validateEmail(email)){
      const token = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      
      fetch(process.env.REACT_APP_API+"/users/recaptcha", {
        method: "POST",
        body: JSON.stringify({
          token: token
        }),
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then(response => {
        if(response.status === 200){
          setLoading(true)
          fetch(process.env.REACT_APP_API+"/users/auth", {
            method: "POST",
            body: JSON.stringify({
              username: email,
              password: password,
              from: "Web"
            }),
            headers: {
              "Content-Type": "application/json",
            }
          })
          .then(response => response.json())
          .then(json => {
            if(json.token){
              saveToLocalStorage("token", json.token)
              setUser(json.user)
              setShow(false)
              setEmail("")
              setPassword("")
            }
            if(json.message){
              setErrorMessage(json.message)
            }
            setLoading(false)
          })
          .catch(err => {
            console.log("err auth", err)
            setErrorMessage("Une erreur est survenue.")
            setLoading(false)
          })
        }
        else{
          setErrorMessage("Une erreur est survenue.")
        }
      })
      .catch(err => {
        console.log("err captcha", err)
      })
    }
    else{
      setErrorMessage("Adresse e-mail invalide.")
    }
  }

  const onKeyPress = (key) => {
    if(key === 'Enter'){
      authenticate()
    }
  }
  
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      {/* <Modal.Header closeButton>
        <Modal.Title>Connexion</Modal.Title>
      </Modal.Header> */}
      <Modal.Body className='p-5'>
        <div className="title">
          Connexion
        </div>
        <div className="subTitle">
          Accédez à votre compte Talian
        </div>
        <form>
          <div className="">
            <div className='' style={{marginBottom: 20}}>
              <input onChange={(event) => setEmail(event.target.value)} value={email} type='email' className='loginInput' placeholder="E-mail"></input>
            </div>
            <div className='' style={{marginBottom: 20}}>
              <input required minLength={6} onKeyDown={(event) => onKeyPress(event.key)} onChange={(event) => setPassword(event.target.value)} value={password} type='password' className='loginInput' placeholder="Mot de passe"></input>
            </div>

            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            />

            <div className={errorMessage ? "errorMsg" : null}>{errorMessage}</div>
            <Button type="button" disabled={email === "" || password === "" || loading} variant='' className={"btn-primary loginBtn"} style={{opacity: email === "" || password === "" || loading ? 0.5 : 1}} onClick={authenticate}>
              Connexion
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
