import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { loadFromLocalStorage } from '../../../../../helps/localStorage'
import "./modal.scss"
import Switch from "react-switch";
import validateEmail from '../../../../../helps/validateEmail'
import parseJwt from '../../../../../helps/parseJwt'

export default function EditUserModal({show, setShow, selectedUser, setUsers}) {

  const userIsActive = selectedUser?.is_active ? true : false
  
  const [name, setName] = useState("")
  const [firstname, setFirstname] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isActive, setIsActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const token = loadFromLocalStorage("token")
  const parsedToken = parseJwt(token)
  
  const DISABLED = (name === selectedUser?.name && firstname === selectedUser?.firstname && email === selectedUser?.email && isActive === userIsActive && !password.length) 
  || (password.length > 0 ? password.length < 6 : false) 
  || loading
  || !name?.length
  || !firstname?.length
  || !email?.length


  useEffect(() => {
    setName(selectedUser?.name)
    setFirstname(selectedUser?.firstname)
    setEmail(selectedUser?.email)
    setPassword("")
    setIsActive(userIsActive)
    setErrorMessage("")
  }, [selectedUser, show])
  
  const edit = () => {
    if(validateEmail(email)){
      setLoading(true)
      fetch(process.env.REACT_APP_API+"/users/edit", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+token
        },
        body: JSON.stringify({
          name,
          firstname,
          email,
          password,
          userId: selectedUser?.id,
          isActive: isActive ? 1 : 0
        })
      })
      .then(response => response.json())
      .then(json => {
        if(json.success){
          setUsers(json.users)
          setShow(false)
        }
        if(json.message){
          setErrorMessage(json?.message)
        }
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        setErrorMessage("Une erreur est survenue.")
      })
    }
    else{
      setErrorMessage("Adresse e-mail invalide.")
    }
  }

  const onKeyPress = (key) => {
    if(key === 'Enter'){
      edit()
    }
  }

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Modifier un utilisateur</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="d-flex flex-column">
            <div className="input-title">Nom<span className='asterisque'> *</span></div>
            <input required maxLength={100} onChange={(event) => setName(event.target.value)} value={name} type='text' className='modal-input' placeholder="Nom"></input>
            <div className="input-title">Prénom<span className='asterisque'> *</span></div>
            <input required maxLength={100} onKeyDown={(event) => onKeyPress(event.key)} onChange={(event) => setFirstname(event.target.value)} value={firstname} type='text' className='modal-input' placeholder="Prénom"></input>
            <div className="input-title">E-mail<span className='asterisque'> *</span></div>
            <input required maxLength={100} onKeyDown={(event) => onKeyPress(event.key)} onChange={(event) => setEmail(event.target.value)} value={email} type='email' className='modal-input' placeholder="E-mail"></input>
            <div className="input-title">Activé</div>
            <Switch
              onChange={setIsActive}
              checked={isActive}
              uncheckedIcon={false}
              checkedIcon={false}
              offColor="#E7E6EB"
              onColor="#00A24F"
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={15}
              width={35}
              disabled={parsedToken?.userId === selectedUser?.id}
            />
            <div className="input-title">Nouveau mot de passe</div>
            <input minLength={6} onKeyDown={(event) => onKeyPress(event.key)} onChange={(event) => setPassword(event.target.value)} value={password} type='text' className='modal-input' placeholder="Mot de passe"></input>
            {password.length > 0 && password.length < 6 && (<div style={{color: "red", marginBottom: 20}}>Le mot de passe doit contenir au moins 6 caractères.</div>)}
            <div style={{color: "red", marginBottom: 20}}>{errorMessage}</div>
            <span className='asterisque'>* Champs obligatoires</span>
            <Button type="button" disabled={DISABLED} variant='' className={"btn-primary"} style={{opacity: DISABLED ? 0.5 : 1, marginTop: "2rem"}} onClick={edit}>
              Modifier
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
