import React, { useEffect, useState } from 'react'
import MenuAdmin from './menu'
import "./admin.scss"
import Users from './users'
import { loadFromLocalStorage } from '../../../helps/localStorage'

export default function Administration() {
  const [page, setPage] = useState("users")
  const [users, setUsers] = useState([])
  
  const token = loadFromLocalStorage("token")

  useEffect(() => {
    fetch(process.env.REACT_APP_API+"/users", {
      method: "GET",
      headers: {
        "Authorization": "Bearer "+token
      }
    })
    .then(response => response.json())
    .then(json => {
      if(json.users){
        setUsers(json?.users)
      }
      if(json.error === "TokenExpiredError"){
        window.location.href = "/"
        localStorage.clear()
      }
    })
    .catch(err => {
      console.log("err", err)
    })
  }, [])
  
  const getCurrentPage = () => {
    switch(page){
      case "users":
        return <Users users={users} setUsers={setUsers} />
      default:
        return <Users users={users} setUsers={setUsers} />
    }
  }

  return (
    <div style={{paddingTop: 76}} className="pb-10 admin-container">
      <MenuAdmin currentPage={page} setCurrentPage={setPage} />
      {getCurrentPage()}
    </div>
  )
}
