import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Background from "../../../../assets/test/898.jpg";
import { Link } from "react-router-dom";
import "./protalcanne.scss";
import { Helmet } from "react-helmet";

const Protalcanne = () => {
  const backgroundWawe = {
    backgroundImage: `url(${Background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "150px",
    borderRadius: "5px",
  };

  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - Protalcanne" />
        <meta
          property="og:description"
          content="Nos Aliments Complémentaires Liquides sont constitués et issus de matières premières non OGM (mélasse de canne, mélasse de betterave, mélasse de glucose, soluble de levurerie et glycérol) et d’additifs (urée alimentaire, prémix oligo-vitaminiques, arômes à la demande)"
        />
      </Helmet>
      <Container className="py-10 mt-5">
        <h2 className="title text-center">Découvrir notre produit Protalcanne</h2>
        <p className="bigText">
          <b>
            Nos Aliments Complémentaires Liquides sont constitués et issus de
            matières premières non OGM
          </b>{" "}
          (mélasse de canne, mélasse de betterave, mélasse de glucose, soluble
          de levurerie et glycérol) <b>et d’additifs</b> (urée alimentaire,
          prémix oligo-vitaminiques, arômes à la demande)
        </p>
        <h2 className="title text-center mt-5">Le mode d'action</h2>
        <Row>
          <Col
            lg={4}
            className="d-flex justify-content-center flex-column align-items-center"
          >
            <span className="subtitle text-center">
              Nourrir et développer
              <br />
              les bactéries du rumen
            </span>
          </Col>
          <Col
            lg={4}
            className="d-flex justify-content-center flex-column align-items-center"
          >
            <img
              src={require("../../../../assets/protalcanne/Produits_vache.jpg")}
              className="imgVacheProduits"
              alt="production-camion-2"
            />
          </Col>
          <Col
            lg={4}
            className="d-flex justify-content-center flex-column align-items-center"
          >
            <span className="subtitle text-center">
              Apporter azote soluble et
              <br />
              énergie sans encombrement
            </span>
          </Col>
        </Row>
      </Container>
      <div className="performances py-5">
        <Container>
          <h2 className="title text-center mb-5">Les performances attendues</h2>
          <Row>
            <Col lg={2} className="">
              <div
                className="d-flex align-items-center flex-column justify-content-center mb-3"
                style={backgroundWawe}
              >
                <span className="ingredient">Sucres</span>
              </div>
            </Col>
            <Col lg={4} className="card p-3">
              <p className="text">
                <b>
                  L’ACL permet d’apporter le complément de sucres nécessaires
                  correspondant aux besoins des animaux.
                </b>{" "}
                Objectif 4 à 5 % de la ration globale, en tenant compte du
                niveau de sucre des fourrages et des aliments concentrés déjà
                présents.
              </p>
              <p className="text ">
                Pour rappel : 1 kg d’aliment complémentaire liquide type « Acl
                13/31 » c’est un apport de 310 g de sucres !
              </p>
            </Col>
            <Col lg={2}>
              <div
                className="d-flex align-items-center flex-column justify-content-center mb-3"
                style={backgroundWawe}
              >
                <span className="ingredient">Energie</span>
              </div>
            </Col>
            <Col lg={4} className="card p-3">
              <p className="text">
                <b>
                  L’aliment liquide apporte de l’énergie sans encombrement !
                </b>
                <br /> C’est pratiquement 1UFL/kg MS selon les formules.
                <br />
                Ex : apporter 1,5kg brut d’ACL 13/31 en début de lactation = 1
                UFL sans encombrement ni substitution.
              </p>
              <p className="text ">
                L’énergie sera apportée par les sucres, mais également par le
                Glycérol selon les formules allant de 12 à 30%. Cette énergie
                nous aidera entre autres à limiter les problèmes métaboliques,
                notamment en début de lactation (acétonémie).
              </p>
            </Col>



            <Col lg={2}>
              <div
                className="d-flex align-items-center flex-column justify-content-center mb-3"
                style={backgroundWawe}
              >
                <span className="ingredient">Appétence</span>
              </div>
            </Col>
            <Col lg={4} className="card p-3">
              <p className="text">
                <b>
                  Dans tous les cas, l’apport d’aliment complémentaire liquide
                  améliore l’appétence de la ration, et par conséquent augmente
                  l’ingestion globale.
                </b>
              </p>
              <p className="text">
                Des essais ont montré qu’apporter 1 à 2 kg d’aliment liquide sur
                une vache laitière, ou 0,5 à 1 kg sur des jeunes bovins à
                l’engraissement, permet aux animaux d’augmenter leur ingestion
                de l’ordre de 5 à 8 %.
              </p>
              <p className="text">
                <b>
                  De façon générale, cela se traduit soit par une augmentation
                  de la production laitière, soit par une augmentation du GMQ
                </b>
              </p>
            </Col>



            <Col lg={2}>
              <div
                className="d-flex align-items-center flex-column justify-content-center mb-3"
                style={backgroundWawe}
              >
                <span className="ingredient">Ingestion</span>
              </div>
            </Col>
            <Col lg={4} className="card p-3">
              <p className="text">
                <b>
                  L’aliment complémentaire liquide facilite et augmente
                  l’ingestion de façon générale, notamment la fibre grossière.
                </b>
              </p>
              <p className="text">
                Aide efficacement à limiter les désordres métaboliques de type
                acidose en incitant à consommer davantage de fibres « efficaces
                ».
              </p>
              <p className="text">
                L’utilisation des ACL reste un moyen efficace de faire consommer
                les fourrages grossiers produits sur l’exploitation, notamment
                les fourrages en libre service, ou mis à disposition.
              </p>
            </Col>



            <Col lg={2}>
              <div
                className="d-flex align-items-center flex-column justify-content-center mb-3"
                style={backgroundWawe}
              >
                <span className="ingredient">Dégradabilité</span>
              </div>
            </Col>
            <Col lg={4} className="card p-3">
              <p className="text">
                <b>
                  L’apport d’aliment complémentaire liquide améliore la
                  dégradabilité{" "}
                </b>
                (au niveau du rumen) des rations, et par conséquent la
                digestibilité (au niveau des intestins)
              </p>
              <p className="text">
                En améliorant la digestibilité des fourrages, grâce au couple
                Azote soluble/Sucres, nous pourrons par exemple faire diminuer
                le nombre de grains de maïs présents dans les bouses mal
                digérées, mais également valoriser l’énergie présente sous forme
                cellulosique.
              </p>
            </Col>



            <Col lg={2}>
              <div
                className="d-flex align-items-center flex-column justify-content-center mb-3"
                style={backgroundWawe}
              >
                <span className="ingredient">Azote / Sucres</span>
              </div>
            </Col>
            <Col lg={4} className="card p-3">
              <p className="text">
                L’apport d’aliment complémentaire liquide dans les rations,
                selon les formules, vous apporte de façon simultanée azote
                soluble et sucres solubles, ayant un vrai effet « starter » au
                niveau du rumen.
              </p>
              <p className="text">
                Apporter 1 kg brut d’aliment complémentaire liquide type « ACL
                36/20 », ou « ACL 45/13 », peut être conseillé pour substituer
                une part de tourteaux de soja ou de colza,{" "}
                <b>tout en apportant des sucres</b> , à partir du moment où
                l’énergie n’est pas un facteur limitant, notamment en PDIE, tout
                en diminuant le coût de complémentation journalier.
              </p>
            </Col>



            <Col lg={2}>
              <div
                className="d-flex align-items-center flex-column justify-content-center mb-3"
                style={backgroundWawe}
              >
                <span className="ingredient">Effet liant</span>
              </div>
            </Col>
            <Col lg={4} className="card p-3">
              <p className="text">
                <b>
                  L’incorporation d’ACL dans les rations fait diminuer
                  considérablement le tri à l’auge,
                </b>{" "}
                et par conséquent les amplitudes de variations de pH Ruminal.
              </p>
              <p className="text ">
                <b>L’incorporation dans les mashs fermiers et fibreux</b> à
                hauteur de 3 à 10%, permet de diminuer la présence de fines et
                l’aspect pulvérulent du produit final.
              </p>
            </Col>

            <hr className="my-5" />

            <Col lg={3}>
              <div
                className="d-flex align-items-center flex-column justify-content-center mb-3"
                style={backgroundWawe}
              >
                <span className="ingredient">Quantité</span>
              </div>
            </Col>
            <Col lg={9} className="card p-3">
              <p className="text">
                <b>
                  La quantité d’ACL variera en fonction des équilibres demandés
                  et des objectifs : de 0,5 kg à 2 kg selon les formules.
                </b>
              </p>
              <p className="text">Exemples :</p>
              <p className="text">
                – 0,5kg d’ACL type « 24/18 » sur un jeune bovin.
              </p>
              <p className="text">
                – Jusqu’à 2 kg d’ACL type « 13/31 » sur une vache laitière en
                début de lactation.
              </p>
              <p className="text">
                – 1 kg à 1,3kg d’ACL type « 45/13 » sur une vache allaitante en
                lactation.
              </p>
              <p className="text">
                Retenons que la quantité moyenne à distribuer est de l’ordre de
                1kg (0,8l) par vache et par jour !
              </p>
              <p className="text">
                <b>
                  L’incorporation d’ACL sur les fourrages grossiers, notamment
                  la paille, se fera de l’ordre de 10 à 15% du poids des balles
                  !
                </b>
              </p>
              <p className="text">
                Exemple : Pour une boule de paille pesant 300kg, nous
                déverserons 45kg d’ACL, soit environ 35l d’un ACL type « 30/12 »
                ou « 24/18 ».
              </p>
              <p className="text ">
                Le principe étant de reconstituer un fourrage avec des valeurs
                azotées proches de celles d’un foin moyen, tout en améliorant la
                dégradabilité de la cellulose.
              </p>
            </Col>
          </Row>
          <div className="d-flex justify-content-center mt-5">
            <Link to="/products/gamme">
              <Button className="btn btn-primary" variant="">
                Découvrir notre gamme
              </Button>
            </Link>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Protalcanne;
