import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { loadFromLocalStorage } from '../../../../../helps/localStorage'
import validateEmail from '../../../../../helps/validateEmail'
import "./modal.scss"

export default function AddUserModal({show, setShow, setUsers}) {

  const [name, setName] = useState("")
  const [firstname, setFirstname] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isAdmin] = useState(false)

  const DISABLED = name === "" || firstname === "" || email === "" || password.length < 6 || loading
  const token = loadFromLocalStorage("token")

  useEffect(() => {
    setName("")
    setFirstname("")
    setEmail("")
    setPassword("")
    setErrorMessage("")
  }, [show])
  
  const addUser = () => {
    if(validateEmail(email)){
      setLoading(true)
      fetch(process.env.REACT_APP_API+"/users/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+token
        },
        body: JSON.stringify({
          name,
          firstname,
          email,
          password,
          isAdmin
        })
      })
      .then(response => response.json())
      .then(json => {
        if(json.success){
          setName("")
          setFirstname("")
          setEmail("")
          setPassword("")
          setUsers(json.users)
          setShow(false)
        }
        if(json.message){
          setErrorMessage(json?.message)
        }
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        setErrorMessage("Une erreur est survenue.")
      })
    }
    else{
      setErrorMessage("Adresse e-mail invalide.")
    }
  }

  const onKeyPress = (key) => {
    if(key === 'Enter'){
      addUser()
    }
  }

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un utilisateur</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="d-flex flex-column">
            <div className="input-title">Nom<span className='asterisque'> *</span></div>
            <input required maxLength={100} onChange={(event) => setName(event.target.value)} value={name} type='text' className='modal-input' placeholder="Nom"></input>
            <div className="input-title">Prénom<span className='asterisque'> *</span></div>
            <input required maxLength={100} onKeyDown={(event) => onKeyPress(event.key)} onChange={(event) => setFirstname(event.target.value)} value={firstname} type='text' className='modal-input' placeholder="Prénom"></input>
            <div className="input-title">E-mail<span className='asterisque'> *</span></div>
            <input required maxLength={100} onKeyDown={(event) => onKeyPress(event.key)} onChange={(event) => setEmail(event.target.value)} value={email} type='email' className='modal-input' placeholder="E-mail"></input>
            <div className="input-title">Mot de passe<span className='asterisque'> *</span></div>
            <input onKeyDown={(event) => onKeyPress(event.key)} onChange={(event) => setPassword(event.target.value)} value={password} type='text' className='modal-input' placeholder="Mot de passe"></input>
            {password.length > 0 && password.length < 6 && (<div style={{color: "red", marginBottom: 20}}>Le mot de passe doit contenir au moins 6 caractères.</div>)}
            {/* <div className="input-title">Administrateur ?<span className='asterisque'> *</span></div>
            <Switch
              onChange={setIsAdmin}
              checked={isAdmin}
              uncheckedIcon={false}
              checkedIcon={false}
              offColor="#E7E6EB"
              onColor="#00A24F"
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              className='mt-2'
              height={15}
              width={35}
              disabled={false}
            /> */}
            <div style={{color: "red", marginBottom: 20}}>{errorMessage}</div>
            <span className='asterisque'>* Champs obligatoires</span>
            <Button type="button" disabled={DISABLED} variant='' className={"btn-primary"} style={{opacity: DISABLED ? 0.5 : 1, marginTop: "2rem"}} onClick={addUser}>
              Ajouter
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
