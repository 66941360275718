import React from "react";
import { Container, Row } from "react-bootstrap";
import CardsActu from "../../cards";
import EndSection from "../../home/end-section";
import { Helmet } from "react-helmet";

const Actualites = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - actualités" />
        <meta
          property="og:description"
          content="Retrouvez les dernières actualités par rapport à TALIAN."
        />
      </Helmet>
      <Container className="py-10">
        <h2 className="title">Actualités</h2>
        <p className="text mb-5">
          Retrouvez les dernières actualités par rapport à TALIAN.
        </p>
        <Row>
          <CardsActu
            text="Téléchargez la fiche d'information détaillée"
            title="Nouveau produit : TALIQ ENERGIE"
            img={require("../../../assets/actu/actu_taliq_energie.png")}
            link={"/pdf/FicheProduitTaliqENERGIE.pdf"}
          />
          <CardsActu
            text="Découvrez dès maintenant l'application TALIAN."
            img={require("../../../assets/actu/actu_appli.jpg")}
            title="Nouveauté : APPLI TALIAN"
            link={"/actualites/app-info"}
          />
          <CardsActu
            text="Les aliments complémentaires liquides, on en parle aussi au niveau national, en partenariat avec l’AFCACIAL : "
            img={require("../../../assets/actu/actu_liquide.jpg")}
            title="L’aliment liquide, c’est du solide !"
            link={"/pdf/aliment_liquide.pdf"}
            link2={"www.aliment-liquide.com"}
          />
          <CardsActu
            img={require("../../../assets/actu/actu_regle.jpg")}
            title="Informations sur la réglementation"
            link={"/pdf/risque_alimentaire.pdf"}
          />
          <CardsActu
            text="Remplacer une partie de l’amidon par des sucres pourrait améliorer la prise alimentaire et la production de vos vaches."
            img={require("../../../assets/actu/actu_sucre.jpg")}
            title="Ajoutez des sucres à la ration pour favoriser la production"
            link={"/actualites/20220916"}
          />
        </Row>
        <h2 className="title mt-5">Bulletins techniques</h2>
        <p className="text mb-5">
          Retrouvez les dernièrs bulletins techniques par rapport à TALIAN.
        </p>
        <Row>
        <CardsActu
            text="Découvrez le dernier bulletin technique disponible."
            title="Décembre 2024"
            img={require("../../../assets/actu/actu_bule.jpg")}
            link={"/pdf/decembre_2024.pdf"}
          />
        <CardsActu
            text="Bulletin technique de Septembre 2024."
            title="Septembre 2024"
            img={require("../../../assets/actu/actu_bule.jpg")}
            link={"/pdf/septembre_2024.pdf"}
          />
        <CardsActu
            text="Bulletin technique de Mai 2024."
            title="Mai 2024"
            img={require("../../../assets/actu/actu_bule.jpg")}
            link={"/pdf/mai_2024.pdf"}
          />
          <CardsActu
            text="Bulletin technique du mois de Novembre 2023."
            title="Novembre 2023"
            img={require("../../../assets/actu/actu_bule.jpg")}
            link={"/pdf/novembre_2023.pdf"}
          />
          <CardsActu
            text="Bulletin technique du mois de Juin 2023."
            title="Juin 2023"
            img={require("../../../assets/actu/actu_bule.jpg")}
            link={"/pdf/juin_2023.pdf"}
          />
          <CardsActu
            text="Pour bien produire cet hiver, n'oubliez pas les sucres !"
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Octobre 2022"
            link={"/pdf/octobre_2022.pdf"}
          />
          <CardsActu
            text="Flash récolte fourrages 2022"
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Juillet 2022 :"
            link={"/pdf/juillet_2022.pdf"}
          />
          <CardsActu
            text="Interprétation Valeurs maïs récolte 2021"
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Janvier 2022 :"
            link={"/pdf/janvier_2022.pdf"}
          />
          <CardsActu
            text="L’aliment complémentaire liquide : une source azotée NON OGM !"
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Avril 2021 :"
            link={"/pdf/avril_2021.pdf"}
          />
          <CardsActu
            text="Valorisation et optimisation des protéines de la ration."
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Mars 2021 :"
            link={"/pdf/mars_2021.pdf"}
          />
          <CardsActu
            text="L’alimentation liquide pour les vaches taries : Confort ruminal et début de lactation."
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Septembre 2020"
            link={"/pdf/septembre_2020.pdf"}
          />
          <CardsActu
            text="La valorisation des fourrages par les aliments complémentaires liquides."
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Mars 2020"
            link={"/pdf/mars_2020.pdf"}
          />
          <CardsActu
            text="L’aliment liquide avec les betteraves fourragères."
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Décembre 2019"
            link={"/pdf/decembre_2019.pdf"}
          />
          <CardsActu
            text="6 bonnes raisons d’utiliser un aliment liquide pour l’élevage allaitant."
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Novembre 2019"
            link={"/pdf/novembre_2019.pdf"}
          />
          <CardsActu
            text="La complémentation des ensilages maïs « cru 2019 »"
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Octobre 2019"
            link={"/pdf/octobre_2019.pdf"}
          />
          <CardsActu
            text="Engraissement"
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Avril 2019"
            link={"/pdf/avril_2019.pdf"}
          />
          <CardsActu
            text="Ensilage de maïs récolté en période de sécheresse"
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Septembre 2018"
            link={"/pdf/septembre_2018.pdf"}
          />
          <CardsActu
            text="L’Aliment complémentaire liquide : une source de protéines Non-OGM"
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Février 2018"
            link={"/pdf/fevrier_2018.pdf"}
          />
          <CardsActu
            text="La complémentation des ensilages maïs « cru 2017 »"
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Novembre 2017"
            link={"/pdf/novembre_2017.pdf"}
          />
          <CardsActu
            text="La vache au tarissement ! Une période cruciale et déterminante"
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Août 2017"
            link={"/pdf/aout_2017.pdf"}
          />
          <CardsActu
            text="La complémentation des animaux en période de sécheresse"
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Juillet 2017"
            link={"/pdf/juillet_2017.pdf"}
          />
          <CardsActu
            text="Des fourrages sucrés pour une meilleure performance !"
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Juin 2017"
            link={"/pdf/juin_2017.pdf"}
          />
          <CardsActu
            text="L’utilisation de l’aliment complémentaire liquide en période de pâturage"
            img={require("../../../assets/actu/actu_bule.jpg")}
            title="Mai 2017"
            link={"/pdf/mai_2017.pdf"}
          />
        </Row>
      </Container>
      <EndSection />
    </>
  );
};

export default Actualites;
