import React from "react";
import { Container } from "react-bootstrap";
import imgApp from "../../../assets/actu/actu_appli.jpg";
import logoTalian from "../../../assets/logo/logo-talian.png";
import logoMaloisel from "../../../assets/logo/logo-maloisel.jpg";
import "./actualites.scss";

const AppInfo = () => {
  return (
    <Container className="py-10 cont-small">

          <h2 className="title">Découvrez l'Application TALIAN</h2>
     
          <div className="text-center mb-5">
            <img src={imgApp} className="photo-app" alt="img-app" />
          </div>
       
          
          <p className="text mb-5 appDescription">
            <strong>Talian et Maloisel</strong> ont mis au point une nouvelle application sur Smartphone : un <strong>Outil
            d’aide à la décision</strong>, destiné à nos conseillers techniques terrains.
            Cette application a pour objectif d’ aider à <span className="text-green">déterminer la formule
            d’aliment complémentaire liquide la plus adaptée en fonction des
            caractéristiques de la ration, et des objectifs de production</span>.  <br />
            Elle concerne les rations vaches laitières, vaches allaitantes, jeunes bovins
            à l’engraissement et les ovins. Cette application est utilisable sur
            Android et Apple et se télécharge très facilement. <br /> <br />
            N’hésitez pas à <a className="btn btn-sm btn-primary" href="mailto:talian@sa-talian.fr">nous contacter</a> si vous souhaitez l’utiliser.
          </p>
          <div className="logoContainer">
            <img src={logoTalian} className="logo" alt="logo Talian" />
            <img src={logoMaloisel} className="logo" alt="logo Maloisel" />
          </div>
   
    </Container>
  );
};

export default AppInfo;
