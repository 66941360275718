import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import UserForm from "../../form";
import "./contact.scss";
import { Helmet } from "react-helmet";

const Contact = (props) => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - contact" />
        <meta
          property="og:description"
          content="Des questions sur nos produits ? Sur la livraison ?"
        />
      </Helmet>

      <Container className="py-10">
        <Row className="align-items-center">
          <Col className="form" lg={6}>
            <h2 className="mb-3 title">Contactez-nous !</h2>
            <p className="text-col-left-contact">
              Des questions sur nos produits ? Sur la livraison ?<br />
              Vous pouvez nous envoyer un message via ce formulaire.
              <br />
              Nous vous répondrons dans les plus brefs délais.
              <br />
            </p>
            <hr />
            <UserForm />
          </Col>
          <Col lg={6} className="text-center">
            <p className="info-col-right-contact">
              Vous pouvez également nous contacter
            </p>
            <p className="title-col-right-contact mb-0">Par téléphone :</p>
            <span className="info-col-right-contact">03.26.07.71.92</span>
            <p className="title-col-right-contact mb-0 mt-4">Par fax :</p>
            <span className="info-col-right-contact">03.26.07.45.29</span>
            <p className="title-col-right-contact mb-0 mt-4">Par email :</p>
            <span className="info-col-right-contact">talian@sa-talian.fr</span>
            <p className="title-col-right-contact mb-0 mt-4">Par courrier :</p>
            <span className="info-col-right-contact">
              SA TALIAN, 19 rue Maurice Prévoteau, 51100 REIMS
            </span>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;
