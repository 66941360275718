import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import NavNutri from "../../navNutrition";
import "./oad.scss";
import { Helmet } from "react-helmet";
import { Switch } from "react-router-dom";

const Contact = (props) => {

  //inputs
  const [label, setLabel] = useState(false);
  const [prot_in_rb, setProt_in_rb ] = useState(10);
  const [uree_in_rb, setUree_in_rb] = useState(false);
  const [mash_sec, setMash_sec] = useState(false);
  const [tcb, setTcb] = useState(12.5);
  const [mat_sec, setMat_sec] = useState(2);

  //params
  const [params, setParams] = useState({
    prot: {
      coef: 5,
      values: [
        {
          min : 10,
          max: 11,
          coef: 5,
          value: {
            energie: 3,
            equilibre: 0,
            proteine: 0
          }
        }, 
        {
          min : 11.5,
          max: 13,
          value: {
            energie: 0,
            equilibre: 1,
            proteine: 0
          }
        }, 
        {
          min : 13.5,
          max: 20,
          value: {
            energie: 1,
            equilibre: 3,
            proteine: 2
          }
        }, 
      ]
    },
    
    tcb: {
      coef: 2,
      values: [
        {
          min : 12.5,
          max: 15,
          value: {
            energie: 0,
            equilibre: 0,
            proteine: 0
          }
        }, 
        {
          min : 15.5,
          max: 21,
          value: {
            energie: 1,
            equilibre: 1,
            proteine: 1
          }
        }, 
        {
          min : 21.5,
          max: 25,
          value: {
            energie: 2,
            equilibre: 2,
            proteine: 2
          }
        }, 
      ]
    }, 

    matiere_seche: {
      coef: 4,
      values: [
        {
          min : 0,
          max: 16.5,
          value: {
            energie: 3,
            equilibre: 1,
            proteine: 1
          }
        }, 
        {
          min : 17,
          max: 22,
          value: {
            energie: 2,
            equilibre: 1,
            proteine: 1
          }
        } 
      ]
    }

  })

  //data calculees
  const [energie, setEnergie] = useState(0);
  const [equilibre, setEquilibre] = useState(0)
  const [proteine, setProteine] = useState(0);


  //indicateurs
  const [uree, setUree] = useState(false);
  const [glycerol, setGlycerol] = useState(false);
  const [comment, setComment] = useState("")

  const [ernergieColor, setErnergieColor] = useState("")
  const [equilibreColor, setEquilibreColor] = useState("")
  const [proteineColor, setProteineColor] = useState("")




  useEffect(() => {
    compute(prot_in_rb, tcb, mat_sec)
  }, [prot_in_rb, tcb, mat_sec])

  useEffect(() => {

    let commentLabel = ""
    let commentUree = ""
    let finalComment = ""

     if (label) {commentLabel = "Pas d'urée car Label"}
     if (uree_in_rb) {commentUree = "Pas d'urée car déja présent dans la ration"}
    
    finalComment = commentLabel + " " +  commentUree

    setComment(finalComment)

  }, [label, uree_in_rb])


  const compute = (p, t, m) => {

    p = parseFloat(p)
    t = parseFloat(t)
    m = parseFloat(m)

    let _energie = 0
    let _equilibre = 0
    let _proteine = 0

    for (let i = 0; i < params?.prot?.values?.length; i++) {
      if (p >= params.prot.values[i].min && p <= params.prot.values[i].max) {
        _energie += params.prot.values[i].value.energie * params.prot.coef
        _equilibre += params.prot.values[i].value.equilibre * params.prot.coef
        _proteine += params.prot.values[i].value.proteine * params.prot.coef
      }
    }
    for (let i = 0; i < params?.tcb?.values?.length; i++) {
      if (t >= params.tcb.values[i].min && t <= params.tcb.values[i].max) {
        _energie += params.tcb.values[i].value.energie * params.tcb.coef
        _equilibre += params.tcb.values[i].value.equilibre * params.tcb.coef
        _proteine += params.tcb.values[i].value.proteine * params.tcb.coef
      }
    }
    for (let i = 0; i < params?.matiere_seche?.values?.length; i++) {
      if (m >= params.matiere_seche.values[i].min && m <= params.matiere_seche.values[i].max) {
        _energie += params.matiere_seche.values[i].value.energie * params.matiere_seche.coef
        _equilibre += params.matiere_seche.values[i].value.equilibre * params.matiere_seche.coef
        _proteine += params.matiere_seche.values[i].value.proteine * params.matiere_seche.coef
      }
    }

    setEnergie(_energie)
    setEquilibre(_equilibre)
    setProteine(_proteine)

    let podium = [
      { name: 'energie', value: _energie },
      { name: 'equilibre', value: _equilibre },
      { name: 'proteine', value: _proteine }
    ];
    
    podium.sort((a, b) => a.value - b.value);

    switch (podium[0].name) {
      case 'energie':
        setErnergieColor("red")
        break;
      case 'equilibre':
        setEquilibreColor("red")
        break;
      case 'proteine':
        setProteineColor("red")
      break;
      default:
        break;
    }
    switch (podium[1].name) {
      case 'energie':
        setErnergieColor("orange")
        break;
      case 'equilibre':
        setEquilibreColor("orange")
        break;
      case 'proteine':
        setProteineColor("orange")
      break;
      default:
        break;
    }
    switch (podium[2].name) {
      case 'energie':
        setErnergieColor("green")
        break;
      case 'equilibre':
        setEquilibreColor("green")
        break;
      case 'proteine':
        setProteineColor("green")
      break;
      default:
        break;
    }
    
    
    if (_energie === _equilibre){
      setErnergieColor("green")
      setEquilibreColor("orange")
    }
    if (_energie === _proteine){
      setErnergieColor("green")
      setProteineColor("orange")
    }
    if (_equilibre === _proteine){
      setProteineColor("orange")
      setEquilibreColor("orange")
    }
    


  }
  


  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - contact" />
        <meta
          property="og:oad"
          content="L'Outil de recommandation gamme Talian"
        />
      </Helmet>

      <NavNutri eventKey="engraissement" />

      <Container className="py-10">
          
        <div className="h1 mb-5">Engraissement</div>

        <Row>
          <Col>
            <Form.Check type="switch" label="Label AOP / AOC" onChange={(e) => setLabel(!label)} />    

            <Form.Label>Taux de protéine dans la ration de base {prot_in_rb} </Form.Label>
            <Form.Range id="prot_in_rb" defaultValue={10} step={0.5} min={10} max={20} onChange={(e) => setProt_in_rb(e.target.value)} />

            <Form.Check type="switch" label="Urée dans la ration de base" onChange={(e) => setUree_in_rb(!uree_in_rb)} />   

            <Form.Check type="switch" label="Mash sec" onChange={(e) => setMash_sec(!mash_sec)} />            

            <Form.Label>Taux de cellulose (dans RB) {tcb} </Form.Label>
            <Form.Range id="tcb" defaultValue={12.5} step={0.5} min={12.5} max={25} onChange={(e) => setTcb(e.target.value)} />

            <Form.Label>Matière sèche ingérée (kg) {mat_sec}</Form.Label>
            <Form.Range id="mat_sec" defaultValue={2} step={0.5} min={2} max={22} onChange={(e) => setMat_sec(e.target.value)} />

          </Col>
          <Col>
            <Table striped bordered hover className="mt-5">
              <thead>
                <tr>
                  <th></th>
                  <th>ENERGIE</th>
        
                  <th>EQUILIBRE</th>
              
                  <th>PROTEINE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Résultat</td>
                  <td className={ernergieColor}>{energie}</td>
                  <td className={equilibreColor}>{equilibre}</td>
                  <td className={proteineColor}>{proteine}</td>
                </tr>
                <tr>
                  <td>Commentaire</td>
                  <td colSpan={3}>{comment}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>


        

  



        

      </Container>
    </>
  );
};

export default Contact;
