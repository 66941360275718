import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Image, Container, Row } from 'react-bootstrap';
import './404.css';

const NotFound = () => {
  return (
    <Container className="py-10">
      <Row>
        <Col lg={12}>
          <div className='d-flex justify-content-center'>
            <Image src={require('../../../assets/404/404.png')} className="img-404" alt="illustration_404" />
          </div>
          <p className='text-center text'>Vous êtes perdu ?</p>
          <div className='d-flex justify-content-center'>
            <Link to="/">
              <Button className="button" variant="">Revenir à l'accueil</Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default NotFound;