import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./history.css";
import { Helmet } from "react-helmet";

const History = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - Histoire" />
        <meta
          property="og:description"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
      </Helmet>
      <div className="backgroundGrey py-10">
        <Container>
          <Row className="justify-content-center">
            <Col lg={9}>
              <h2 className="title">Histoire</h2>
              <h3 className="my-5">15 février 1975</h3>
              <img
                className="logoHistory"
                src={require("../../../../assets/history/Histoire_AncienLogo-2.jpg")}
                alt="logo-talian"
              />
              <p className="mt-4">
                La SARL Talian est fondée par Monsieur Olivier LALANNE, à Reims.
              </p>
              <p>
                Son nom signifie : <b>T</b>echnique et <b>ALI</b>mentation{" "}
                <b>AN</b>imale
              </p>
              <p>
                A son commencement Talian fait du conseil et de la vente
                d’aliments complets et composés pour porcs, volailles et lapins.
              </p>
            </Col>
            <Col lg={3} className="d-flex flex-column align-items-center">
              <img
                src={require("../../../../assets/history/Histoire_OlivierLalanne.jpg")}
                className="imgRounded"
                alt="histoire"
              />
              <p className="mt-3 text fw-bold">Olivier LALANNE</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="py-10 d-flex flex-column align-items-center">
        <Row>
          <Col lg={12}>
            <h3>1988</h3>
            <p className="mt-4">
              La société Talian se diversifie dans l’aliment complémentaire
              liquide pour bovins et ovins.
            </p>
            <img
              src={require("../../../../assets/history/Histoire_EvolTonnage2019.jpg")}
              className="imgGraphic"
              alt="histoire"
            />
          </Col>
        </Row>
      </Container>
      <div className="backgroundGrey py-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={9}>
              <h3>Septembre 2014</h3>
              <img
                src={require("../../../../assets/history/Histoire_Logos.jpg")}
                className="multipleLogos"
                alt="histoire"
              />
              <p className="mt-4">
                Suite à la retraite de Monsieur LALANNE, le groupe coopératif
                Céréna devient l’actionnaire principal et unique de Talian.
              </p>
              <p>Monsieur Stéphane MICHEL est le nouveau directeur.</p>
              <p>
                Aujourd’hui, Talian produit et livre <b>20 000 Tonnes/an</b>{" "}
                d’ACL.
              </p>
              <p>
                La société Talian livre sur toute la France, la Belgique et le
                Luxembourg. Les principales zones de livraison étant cependant
                les Hauts de France, le Grand Est et la Normandie.
              </p>
            </Col>
            <Col lg={3} className="d-flex flex-column align-items-center">
              <img
                src={require("../../../../assets/history/Equipe_Stephane.jpg")}
                className="imgRounded"
                alt="histoire"
              />
              <p className="mt-3 text fw-bold">Stéphane MICHEL</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default History;
