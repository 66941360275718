import React from 'react';
import './footer.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className='backgroundFooter'>
      <Container>
        <Row>
          <Col lg={3} className='styleGrey'>
          <img className='logoTalian ms-2' src={require('../../assets/logo/logo-talian.png')} alt="talian-icon" />
            <div className='mt-3'> Suivez-nous sur facebook
              <a href="https://www.facebook.com/SATalianSourceDeProgres" target="_blank">
                <img className='iconFacebook ms-2' src={require('../../assets/footer/facebook-square.png')} alt="facebook-icon" />
              </a>
            </div>
            <div className='mt-3'> Suivez-nous sur Linkedin
              <a href="https://www.linkedin.com/company/talian-sa/about/" target="_blank">
                <img className='iconFacebook ms-2' src={require('../../assets/footer/linkedin.png')} alt="facebook-icon" />
              </a>
            </div>

            <div className="mt-2">Tél : 03.23.22.80.53</div>
            <div className="">@ : talian@sa-talian.fr</div>
            <div className="mt-2">
              SA TALIAN <br /> 26 avenue de la libération <br /> 02270 CRECY SUR SERRE
            </div>
          </Col>
          <Col lg={3} className="footMenu">
          <div className='footerTitle'>
              <strong>Nos offres </strong>
            </div>
            <Link to="/nutrition">
              <span className='me-3'>La nutrition animale</span>
            </Link>
            <Link to="/products/protalcanne">
              <span className=''>Gamme Protalcanne</span>
            </Link>
            <Link to="/products/gamme">
              <span className=''>Notre gamme complète</span>
            </Link>
            <Link to="/products/materiel">
              <span className=''>Sélection matériels</span>
            </Link>
            <Link to="/company/production">
              <span className=''>Production et Logistique</span>
            </Link>
          </Col>
          <Col lg={3} className="footMenu">
          <div className='footerTitle'>
              <strong>Services et Qualité </strong>
            </div>
            <Link to="/company/qualite">
              <span className=''>Qualité et certifications</span>
            </Link>
            <Link to="/company/services">
              <span className=''>Services</span>
            </Link>
            <Link to="/livraisons">
              <span className=''>Livraisons</span>
            </Link>
            <Link to="/contact">
              <span className='me-3'>Contact</span>
            </Link>
            <Link to="/legal">
              <span className=''>Mentions légales</span>
            </Link>
          </Col>
          
          <Col lg={3} className="footMenu">

            <div className='footerTitle'>
              <strong>Nos certifications </strong>
            </div>
            <div className='logos'>
              <img className='logoTalian mt-2' src={require('../../assets/qualite/logoUE_AB.png')} alt="talian-icon" />
              <img className='logoTalian mt-2' src={require('../../assets/qualite/Entreprise_Qualite_Logos-2.jpg')} alt="talian-icon" /> 
            </div>


          
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
