import React from "react";
import { Col, Card, Button } from "react-bootstrap";
import "./card.scss";

const CardsActu = (props) => {
  return (
    <Col lg={3} className="mt-4">
      <Card className="cardDesign">
        <Card.Img className="imgCard" variant="top" src={props.img} />
        <Card.Body className="d-flex flex-column justify-content-between">
          <div>
            <Card.Title>{props.title}</Card.Title>
            <Card.Text className="my-3">{props.text} <a className="link" target="\blank" href={"http://"+props.link2}>{props.link2}</a> </Card.Text>
          </div>
          <div>
            <a href={props.link} target="_blank">
              <Button variant="" className="btn btn-primary">
                En savoir plus
              </Button>
            </a>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default CardsActu;
