import React from "react";
import { Container, Carousel } from "react-bootstrap";
import "./vache-allait.css";
import { Helmet } from "react-helmet";
import NavNutri from "../../../navNutrition";

const VacheAllait = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - Les vaches allaitantes" />
        <meta
          property="og:description"
          content="L’intérêt de l’utilisation des aliments complémentaires liquides dans les rations des vaches allaitantes, ainsi que des génisses d’élevage est multiple."
        />
      </Helmet>
      <Carousel>
        <Carousel.Item>
          <img
            className="w-100 carouselVacheAllait"
            src={require("../../../../assets/vaches/Nutrition_Allaitantes_2.jpg")}
            alt="image-vache-allaitante"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100 carouselVacheAllait"
            src={require("../../../../assets/vaches/Nutrition_Allaitantes_3.jpg")}
            alt="image-vache-allaitante"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100 carouselVacheAllait"
            src={require("../../../../assets/vaches/Nutrition_Allaitantes_4.jpg")}
            alt="image-vache-allaitante"
          />
        </Carousel.Item>
      </Carousel>
      <Container className="py-5">

      <NavNutri eventKey="allaitantes" />


        <h2 className="title mb-5">Les vaches allaitantes</h2>
        <p className="text fw-bold">
          L’intérêt de l’utilisation des aliments complémentaires liquides dans
          les rations des vaches allaitantes, ainsi que des génisses d’élevage
          est multiple.
        </p>
        <p className="text">
          Encore beaucoup utilisé, à juste titre, comme complément pour faire
          consommer davantage de fibres, notamment dans le cas où le pâturage
          est faible, l’ACL est également conseillé comme apport en protéine
          dans les rations des vaches allaitantes et génisses d’élevage,
          remplaçant tout ou une partie de l’apport azoté. Les formules
          d’aliments proposées s’adaptent aux différentes rations présentes sur
          le territoire national, en ration sèche comme en ration humide, en
          rationné, ou en libre-service (formule adaptée).
        </p>
        <p className="text">
          Dans bon nombre de régions, l’ACL est quasiment l’apport exclusif de
          la matière azotée dans les rations de base des vaches allaitantes et
          génisses d’élevage, déjà correctement pourvues en énergie, non
          déficitaires en PDIE, de par son coût, sa facilité d’utilisation, et
          son appétence, favorisant en plus la consommation de fibres
          grossières.
        </p>
        <p className="text">
          L’utilisation des ACL, dans les rations des vaches allaitantes et
          génisses, est une réelle solution à la fois technique et économique.
          Des formules existent pour chaque type de ration, avec des fourrages
          pouvant varier d’une région à l’autre.
        </p>
      </Container>
    </>
  );
};

export default VacheAllait;
