import React from "react";
import { Container, Accordion, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Gamme = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - Notre gamme" />
        <meta
          property="og:description"
          content="A chaque ration correspond une formule d'ACL !"
        />
      </Helmet>
      <Container className="py-10">
        <h2 className="title text-center">Tous nos produits</h2>
        <p className="subTitle">
          A chaque ration correspond une formule d'ACL !
        </p>

        <h3 className="subtitle">Nouveau produit : TALIQ ENERGIE</h3>

        <Accordion className="my-5">
          <Accordion.Item eventKey="16">
            <Accordion.Header>TALIQ ENERGIE</Accordion.Header>
            <Accordion.Body className="text">
            Extrait laitier à base de lactose hydrolysé - Mélasse Isomaltulose - Sorbitol - Glycérine - Acides organiques - Niacine - Vit B 12 - Arôme.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <h3 className="subtitle">Notre gamme protalcanne</h3>

        <Accordion  className="my-5">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Protalcanne 13/31</Accordion.Header>
            <Accordion.Body className="text">
              Formule type sucre et énergie sans urée, 13% de protéines et 31%
              de sucres. Particulièrement adaptée pour les vaches laitières en
              début de lactation, mais également la fabrication de mélanges
              fibreux ou fermiers.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Protalcanne 24/18</Accordion.Header>
            <Accordion.Body className="text">
              Formule équilibrée sans urée, 24 % de protéines et 18 % de sucres.
              Apport de sucres et azote soluble de façon simultanée.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>Protalcanne 30/12</Accordion.Header>
            <Accordion.Body className="text">
              Formule type « azoté », sans urée, 30 % de protéines et 12 % de
              sucres. Apport d’azote soluble et sucres de façon simultanée.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Protalcanne 36/20</Accordion.Header>
            <Accordion.Body className="text">
              Formule type azote/sucres, avec urée, 36 % de protéines et 20% de
              sucres. Apport d’azote et de sucres de façon simultanée.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>Protalcanne 38/3 </Accordion.Header>
            <Accordion.Body className="text">
              Formule type azoté, 38 % de protéines, 3 % de sucres, sans urée.
              Principalement utilisée dans les rations déficitaires en azote
              soluble, déjà pourvues en sucre. Formule idéale pour les rations
              avec présence de betteraves fourragères.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>Protalcanne 40/4</Accordion.Header>
            <Accordion.Body className="text">
              Formule type azote, avec urée, 40 % de protéines et 4 % de sucres.
              Principalement utilisée afin de corriger les rations déficitaires
              en azote et déjà pourvue en sucre. Formule idéale pour les rations
              avec présence de betteraves fourragères.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>Protalcanne 45/13</Accordion.Header>
            <Accordion.Body className="text">
              Formule type azote, avec urée, 45 % de protéines et 13 % de
              sucres. Principalement utilisée afin de corriger les rations
              déficitaires en azote. Fort pouvoir de correction azoté.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header>Protalcanne 24/14 Glycérol</Accordion.Header>
            <Accordion.Body className="text">
              Formule équilibrée sans urée, 24 % de protéines, 14 % de sucres,
              et 12 % de Glycérol. Cette formule riche en énergie, notamment
              avec l’aide du glycérol, convient particulièrement aux vaches
              laitières en début de lactation (afin de limiter le déficit
              énergétique) ainsi qu’aux jeunes bovins à l’engraissement.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <h3 className="subtitle">Gamme nationale Labeliq</h3>
        <p className="text">Gamme respectant les cahiers des charges Label.</p>

        <Accordion className="my-5">
          <Accordion.Item eventKey="11">
            <Accordion.Header>Labeliq Sucre</Accordion.Header>
            <Accordion.Body className="text">
              Formule orientée apport de sucre et d’énergie sans urée, 13% de
              protéines et 35% de sucres. Particulièrement adaptée pour les
              vaches laitières en début de lactation, mais également la
              fabrication de mélanges fibreux ou fermiers.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="12">
            <Accordion.Header>Labeliq Equilibre</Accordion.Header>
            <Accordion.Body className="text">
              Formule équilibrée sans urée, 24 % de protéines et 18 % de sucres.
              Apport de sucres et azote soluble de façon simultanée.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="13">
            <Accordion.Header>Labeliq Maïs</Accordion.Header>
            <Accordion.Body className="text">
              Formule orientée protéines sans urée, et avec un minimum sucré, 30
              % de protéines et 15 % de sucres. Apport d’azote soluble et sucres
              de façon simultanée.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="14">
            <Accordion.Header>Labeliq 35</Accordion.Header>
            <Accordion.Body className="text">
              Formule type azoté, 35 % de protéines, 4 % de sucres, sans urée.
              Principalement utilisée dans les rations déficitaires en azote
              soluble, déjà pourvues en sucre. Formule idéale pour les rations
              avec présence de betteraves fourragères.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="15">
            <Accordion.Header>Labeliq Protéines</Accordion.Header>
            <Accordion.Body className="text">
              Formule protéinée, sans urée, 40 % de protéines et 4 % de sucres.
              Principalement utilisée afin de corriger les rations déficitaires
              en azote et déjà pourvue en sucre.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <h3 className="subtitle">Nos autres produits</h3>

        <Accordion className="mt-5">
          <Accordion.Item eventKey="8">
            <Accordion.Header>Protalmash</Accordion.Header>
            <Accordion.Body className="text">
              Formule équilibrée sans urée, 24% de protéines et 18 % de sucres.
              Apport de sucres et azote soluble de façon simultanée. Cette
              formule spécifique est principalement utilisée pour les mash
              fermiers, mash usine, mash fibreux, de 3 à 10 %, grâce à sa
              fluidité mais surtout à son arôme très prononcé.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="9">
            <Accordion.Header>Mélasse de betterave</Accordion.Header>
            <Accordion.Body className="text">
              La mélasse de betteraves est une matière première très riche en
              sucre, variant de 45 à 48 % de sucre et pauvre en protéines. Elle
              rentre en composition dans la majorité de nos formules d’aliment
              liquide.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="10">
            <Accordion.Header>Mélasse de canne</Accordion.Header>
            <Accordion.Body className="text">
              La mélasse de canne pure est une matière première, très riche en
              sucre, variant entre 42 et 45 % , et très pauvre en protéines.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className="d-flex justify-content-center mt-5">
          <Link to="/livraisons">
            <Button className="btn btn-primary" variant="">
              Comment commander nos produits ?
            </Button>
          </Link>
        </div>
      </Container>
    </>
  );
};

export default Gamme;
