import React from "react";
import { Container, Carousel, Nav } from "react-bootstrap";
import { Helmet } from "react-helmet";
import NavNutri from "../../../navNutrition";
const Engraissement = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - Engraissement" />
        <meta
          property="og:description"
          content="L’utilisation des ACL dans les rations d’engraissement a deux objectifs principaux :"
        />
      </Helmet>
      <Carousel>
        <Carousel.Item>
          <img
            className="w-100 carouselVacheAllait"
            src={require("../../../../assets/vaches/Home_Jeunes.jpg")}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100 carouselVacheAllait"
            src={require("../../../../assets/vaches/Nutrition_Jeunes_2.jpg")}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100 carouselVacheAllait"
            src={require("../../../../assets/vaches/Nutrition_Jeunes_3a.jpg")}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
      <Container className="py-5">

        <NavNutri eventKey="engraissement" />

        <h2 className="title mb-5">Engraissement</h2>
        <p className="text fw-bold">
          L’utilisation des ACL dans les rations d’engraissement a deux
          objectifs principaux :
        </p>
        <p className="text">
          Apporter de l’appétence de façon globale à la ration mélangée
          distribuée, favorisant ainsi l’augmentation de l’ingestion de l’ordre
          de 5 à 8 %.
        </p>
        <p className="text">
          Apporter sucres et azote soluble de façon simultanée, afin d’assurer
          le bon fonctionnement du rumen, tout en favorisant la digestibilité de
          la cellulose.
        </p>
        <p className="text">
          Certaines formules bien spécifiques dans la gamme des aliments liquide
          « Talian » ont pour objectif, avec l’aide d’additifs technologiques
          (prétraitement des fourrages), de contribuer à une meilleure
          digestibilité de la cellulose, afin d’optimiser et valoriser au
          maximum la ration de base.
        </p>
        <p className="text">
          Pour les rations d’engraissement en ration sèche, l’ACL aura les mêmes
          atouts, mais favorisera davantage l’ingestion de fibres, type paille
          de blé, limitant ainsi les désordres métaboliques de type acidose par
          l’aspect mécanique de la fibre consommée dite « efficace ».
        </p>
      </Container>
    </>
  );
};

export default Engraissement;
