import React from "react";
import Background from "../../../assets/test/898.jpg";
import CarouselHome from "../../home/carousel";
import './header.css';

const Header = () => {


  return (
    <div className="headerCar">
      <div className="d-flex container">
        <div className="titleContainer">
          <h1>Nutrition Animale</h1>
          <h2>Fabricant d’Aliment Complémentaire Liquide</h2>
          <p className=" mt-3">
            Depuis trois décennies, l’Aliment Complémentaire Liquide est le cœur de notre métier.
            <br />
            Toujours à l’écoute et au service de nos clients éleveurs,
            nous proposons <br /> des solutions  techniques et économiques avec des produits certifiés de qualité.
          </p>
          <a href="/products/gamme" className="btn btn-primary">Découvrez notre gamme</a>
        </div>
        <div className="caroussel">
          <CarouselHome />
        </div>
      </div>
    </div>
  );
};

export default Header;
