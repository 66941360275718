import React from "react";
import { Container, Button, Image, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./materiel.css";
import { Helmet } from "react-helmet";

const Materiel = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - Matériel" />
        <meta
          property="og:description"
          content="Nous proposons du matériel de stockage et d'aide à la distribution"
        />
      </Helmet>
      <Container className="py-10">
        <h2 className="title">Matériel</h2>
        <h3 className="subtitle mt-5">
          Nous proposons du matériel de stockage et d'aide à la distribution
        </h3>
        <p className="text">Disponible sur devis.</p>
        <Link to="/contact">
          <Button className="button" variant="">
            Contactez-nous
          </Button>
        </Link>
      </Container>
      <div className="backgroundGrey py-5">
        <Container>
          <Row>
            <Col lg={3}>
              <Image
                src={require("../../../../assets/materiel/Produit_Materiel_1.jpg")}
                className="rounded imgMaterial"
                alt="production-camion-2"
              />
              <p className="text-center text mt-3 mb-5">
                Cuve de stockage liquide alimentaire de 5000L à 25 000L
              </p>
            </Col>

            <Col lg={3}>
              <Image
                src={require("../../../../assets/materiel/Produit_Materiel_2.jpg")}
                className="rounded imgMaterial"
                alt="production-camion-2"
              />
              <p className="text-center text mt-3 mb-5">
                Pompe de reprise d’aliment liquide mélassé
              </p>
            </Col>

            <Col lg={3}>
              <Image
                src={require("../../../../assets/materiel/Produit_Materiel_3-1.jpg")}
                className="rounded imgMaterial"
                alt="production-camion-2"
              />
              <p className="text-center text mt-3 mb-5">
                Container plastique de 1000L grillagé
              </p>
            </Col>

            <Col lg={3}>
              <Image
                src={require("../../../../assets/materiel/Produit_Materiel_4.jpg")}
                className="rounded imgMaterial"
                alt="production-camion-2"
              />
              <p className="text-center text mt-3 mb-5">Demi raccord pompier</p>
            </Col>

            <Col lg={3}>
              <Image
                src={require("../../../../assets/materiel/Produit_Materiel_5.jpg")}
                className="rounded imgMaterial"
                alt="production-camion-2"
              />
              <p className="text-center text mt-3 mb-5">Motopompe thermique</p>
            </Col>

            <Col lg={3}>
              <Image
                src={require("../../../../assets/materiel/Produit_Materiel_6.jpg")}
                className="rounded imgMaterial"
                alt="production-camion-2"
              />
              <p className="text-center text mt-3 mb-5">
                Tuyaux avec raccord pompier
              </p>
            </Col>

            <Col lg={3}>
              <Image
                src={require("../../../../assets/materiel/Produit_Materiel_7.jpg")}
                className="rounded imgMaterial"
                alt="production-camion-2"
              />
              <p className="text-center text mt-3 mb-5">
                Vanne d’arrêt quart de tour
              </p>
            </Col>

            <Col lg={3}>
              <Image
                src={require("../../../../assets/materiel/Produit_Materiel_8.jpg")}
                className="rounded imgMaterial"
                alt="production-camion-2"
              />
              <p className="text-center text mt-3 mb-5">
                Coude de distribution
              </p>
            </Col>

            <Col lg={3}>
              <Image
                src={require("../../../../assets/materiel/Produit_Materiel_9.jpg")}
                className="rounded imgMaterial"
                alt="production-camion-2"
              />
              <p className="text-center text mt-3 mb-5">Vanne de cuve</p>
            </Col>

            <Col lg={3}>
              <Image
                src={require("../../../../assets/materiel/Produit_Materiel_10.jpg")}
                className="rounded imgMaterial"
                alt="production-camion-2"
              />
              <p className="text-center text mt-3 mb-5">
                Distributeur libre-service à roues de 1000L
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Materiel;
