import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import "./services.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Services = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - services" />
        <meta
          property="og:description"
          content="Le métier de base de la société TALIAN est la fabrication depuis bientôt 30 ans des aliments complémentaires liquides mélassés. Nos aliments complémentaires liquides sont formulés pour optimiser vos rations, notamment les fourrages produits sur votre exploitation."
        />
      </Helmet>

      <Container className="py-10">
        <h2 className="title">Services</h2>
        <h3 className="subtitle my-5">Suivi technique personnalisé</h3>
        <p className="text">
          Le métier de base de la société TALIAN est la fabrication depuis
          bientôt 30 ans des aliments complémentaires liquides mélassés. Nos
          aliments complémentaires liquides sont formulés pour optimiser vos
          rations, notamment les fourrages produits sur votre exploitation.
        </p>
        <Row>
          <Col lg={3}>
            <img
              src={require("../../../../assets/services/Entreprise_Services_Image.jpg")}
              className="imgServices"
              alt="entreprise-service"
            />
          </Col>
          <Col lg={9}>
            <p className="text">
              Pour cela, la société{" "}
              <b>
                TALIAN propose désormais à la demande un suivi technique
                personnalisé et indépendant
              </b>
              , en bovin lait et en bovin viande.
            </p>
            <p className="text">
              Ce service nutrition consiste à vous conseiller, de façon
              totalement indépendante et objective, dans le but de valoriser au
              maximum les fourrages produits sur votre exploitation. Cette
              démarche nous amène à parler coût de production, rentabilité,
              efficacité alimentaire, et marge, en tenant compte de vos
              objectifs définis au préalable sous forme d’un contrat de suivi.
            </p>
            <p className="text mt-4">
              N’hésitez pas à nous contacter afin que nous puissions établir
              ensemble vos besoins en suivi « expertise nutrition ».
            </p>
            <div className="d-flex justify-content-end">
              <Link to="/contact">
                <Button variant="" className="button">
                  Contactez-nous
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Services;
