import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./livraisons.scss";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Livraisons = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - livraisons" />
        <meta
          property="og:description"
          content="Depuis notre site de fabrication situé dans l’Aisne, avec notre propre flotte de camions-citernes compartimentées, mais également des transporteurs externes respectant nos procédures et notre cahier des charges, nous livrons nos éleveurs directement en ferme, avec un bon de pesée, dans un délai maximum de 8 jours selon les régions."
        />
      </Helmet>

      <div style={{ backgroundColor: "#F3F3F3" }}>
        <Container className="py-10">
          
          <h2 className="title text-center mt-4">Livraisons</h2>

          <p className="text p-5">
                Depuis notre site de fabrication situé dans l’Aisne, avec notre
                propre flotte de camions-citernes compartimentées, mais également
                des transporteurs externes respectant nos procédures et notre cahier
                des charges,{" "}
                <b>
                  {" "}
                  nous livrons nos éleveurs directement en ferme, avec un bon de
                  pesée, dans un délai maximum de 8 jours selon les régions.
                </b>
              </p>

          <Row className="align-items-center">
            <Col>
            <img
                src={require("../../../assets/livraisons/Livraisons_CarteTalian-3.jpg")}
                className="carte-class"
                alt="carte-talian"
              />
              
            </Col>
            <Col>
              <div className="details">
                <p className="subtitle">
                    Vous voulez connaître votre distributeur local ?
                  </p>
                  <p className="text">
                    Contactez-nous par téléphone <b>03 26 07 71 92</b> ou par e-mail{" "}
                    <br />
                    <b>talian@sa-talian.fr</b> afin que nous puissions vous mettre
                    en relation.
                  </p>
                  <p className="text-red m-0">
                    Vous n’êtes pas dans la zone de livraison principale ?
                  </p>
                  <p className="text-red">
                    Nous avons la possibilité de livrer <b>sur toute la France !</b>
                  </p>
                  <p className="text">
                    Uniquement sur devis, en camion complet minimum 22500l (environ
                    28 t), ou par container(s) perdu(s) de 1000l livrés en ferme.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link to="/contact">
                      <Button className="btn btn-primary" variant="">
                        Contactez-nous
                      </Button>
                    </Link>
                  </div>
              </div>
            </Col>
          </Row>
          
          
        </Container>
      </div>
    </>
  );
};

export default Livraisons;
