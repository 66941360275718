import { faSearch, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import AddUserModal from './modal/addUserModal';
import EditUserModal from './modal/editUserModal';
import "./users.scss"

export default function Users({users, setUsers}) {
  
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);

  useEffect(() => {
    setFilteredUsers(users.filter(user => 
      user.name.toLowerCase().includes(search.toLowerCase()) 
      || user.firstname.toLowerCase().includes(search.toLowerCase())
      || user.email.toLowerCase().includes(search.toLowerCase())
    ))
  }, [search, users])

  const editUser = (user) => {
    setSelectedUser(user)
    setShowEditUserModal(true)
  }

  return (
    <div style={{padding: "4rem 2.5rem", width: "100%"}}>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <div style={{display: "flex", marginRight: 20}}>
          <div style={{fontWeight: 600, fontFamily: "Montserrat", color: "black", fontSize: 20}}>Liste des utilisateurs ({users?.length})</div>
          <div onClick={() => setShowAddUserModal(true)} style={{height: 30, width: 30, borderRadius: 15, backgroundColor: "white", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 5, cursor: "pointer"}}>
            <FontAwesomeIcon icon={faUserPlus} color={"#00A24F"} />
          </div>
        </div>

        <div style={{display: "flex", alignItems: "center"}}>
          <FontAwesomeIcon style={{marginRight: 5}} icon={faSearch} color={"black"} />
          <input placeholder='Nom, prénom, e-mail...' className='search-input' type={"search"} value={search} onChange={(event => setSearch(event.target.value))} />
        </div>
      </div>

      <table  style={{width: "100%", marginTop: 20}}>
        <thead>
          <tr style={{border: "1px solid rgb(239 239 253)", height:40, backgroundColor:"white"}}>
            <th
              colSpan={1}
              className="text-header"
            >
              Nom et prénom
            </th>
            <th
              colSpan={1}
              className="text-header"
            >
              Email
            </th>
            <th
              colSpan={1}
              className="text-header"
            >
              État
            </th>
            <th
              colSpan={1}
              className="text-header"
            >
              Inscrit le
            </th>
            <th
              colSpan={1}
              className="text-header"
            >
              Dernière connexion
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody >
          {filteredUsers.map((user) => (
            <tr key={user.id} style={{border: "1px solid rgb(239 239 253)", backgroundColor: "white"}}>
              <td className="text-content">
              {`${user?.name} ${user?.firstname}`}
              </td>
              <td className="text-content">
                {user?.email}
              </td>
              <td className={`text-content ${user?.is_active === 1 ? "active" : "inactive"}`}>
                {user?.is_active === 1 ? "Activé" : "Désactivé"}
              </td>
              <td className="text-content">
                {new Date(user?.created_at).toLocaleDateString('fr-FR')}
              </td>
              <td className="text-content">
                {user?.last_login ?
                  new Date(user?.last_login).toLocaleString('fr-FR')
                :
                  "Jamais"
                }
              </td>
              <td className="text-content">
                <button
                  className="edit-button"
                  onClick={() => editUser(user)}
                >
                  Modifier
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <AddUserModal setUsers={setUsers} show={showAddUserModal} setShow={setShowAddUserModal} />
      <EditUserModal setUsers={setUsers} selectedUser={selectedUser} show={showEditUserModal} setShow={setShowEditUserModal} />
    </div>
  )
}
