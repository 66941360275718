import React from 'react';
import ReactDOM from 'react-dom'
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import App from './components/app/App';

const Root = () => (
  <Router>
    <App />
  </Router>
)

ReactDOM.render(<Root />, document.getElementById('root'))
