import React, { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { Form, Button } from "react-bootstrap";
import InputField from "./inputField";
import TextArea from "./textArea";

export default function UserForm() {
  const [values, setValues] = useState({
    name: "",
    subject: "",
    phone: "",
    address: "",
    message: "",
    email: "",
  });
  const [status, setStatus] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send("service_rwppjkj", "template_i89egnw", values, "lp_LIsdQd2b2Jyj-O")
      .then(
        (response) => {
          setValues({
            name: "",
            subject: "",
            phone: "",
            address: "",
            message: "",
            email: "",
          });
          setStatus("SUCCESS");
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  useEffect(() => {
    if (status === "SUCCESS") {
      setTimeout(() => {
        setStatus("");
      }, 3000);
    }
  }, [status]);

  const handleChange = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <InputField
        value={values.name}
        handleChange={handleChange}
        label="Nom"
        name="name"
        type="text"
        placeholder="Votre nom"
      />
      <InputField
        value={values.address}
        handleChange={handleChange}
        label="Adresse"
        name="address"
        type="text"
        placeholder="Votre adresse"
      />

      <InputField
        value={values.email}
        handleChange={handleChange}
        label="Email"
        name="email"
        type="email"
        placeholder="Votre email"
      />

      <InputField
        value={values.phone}
        handleChange={handleChange}
        label="Téléphone"
        name="phone"
        type="text"
        placeholder="Votre numéro de téléphone"
      />

      <InputField
        value={values.subject}
        handleChange={handleChange}
        label="Sujet"
        name="subject"
        type="text"
        placeholder="Votre sujet"
      />

      <TextArea
        value={values.message}
        handleChange={handleChange}
        label="Message"
        name="message"
      />
      <Button variant="" className="buttonContact" type="submit">
        Envoyer
      </Button>
    </Form>
  );
}
