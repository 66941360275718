import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./production.scss";
import { Helmet } from "react-helmet";

const Production = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - production et logistique" />
        <meta
          property="og:description"
          content="Le site de production se situe depuis le 1 juillet 2017 à Crécy Sur Serre, dans le département de l’Aisne, dans la région des Hauts de France. Cette nouvelle usine est équipée d’une quarantaine de cuves de stockage, pour une capacité d’environ 1000 T. Nous produisons actuellement 20 000 T d’aliment complémentaire liquide par an, qui sont répartis, à travers nos distributeurs, sur l’ensemble du territoire national, ainsi que la Belgique, le Luxembourg, et l’Allemagne."
        />
      </Helmet>

      <div className="coloredBG py-10">
        <Container>
          <h2 className="title mt-5">Production et logistique</h2>
          <Row>
            <Col lg={7}>
              <h3 className="subtitle mt-4 mb-3">Production</h3>
              <p>
                Le site de production se situe depuis le 1 juillet 2017 à Crécy
                Sur Serre, dans le département de l’Aisne, dans la région des
                Hauts de France. Cette nouvelle usine est équipée d’une
                quarantaine de cuves de stockage, pour une capacité d’environ
                1000 T. Nous produisons actuellement 20 000 T d’aliment
                complémentaire liquide par an, qui sont répartis, à travers nos
                distributeurs, sur l’ensemble du territoire national, ainsi que
                la Belgique, le Luxembourg, et l’Allemagne.
              </p>
              <h4 className="text fw-bold mt-4 mb-3">
                Mélanges et fabrication
              </h4>
              <p>
                La structure est équipée d’une station de mélange, permettant de
                fabriquer toute la gamme d’aliments liquides, avec ou sans
                additifs.
              </p>
              <p>
                Les matières premières sont stockées et mélangées en fonction
                des commandes qui arrivent au fur et à mesure. Les proportions
                de chaque matière première varient en fonction des formules
                demandées : de 5 à 75 % de mélasse de betteraves et/ou de canne.
              </p>
              <h4 className="text fw-bold mt-4 mb-3">Formulations</h4>
              <p>
                En fonction des besoins des animaux et des objectifs de
                production en lait et en viande, les formules peuvent varier
                quant à leurs valeurs nutritionnelles : <br />
                – sucre, énergie (UFL, UFV) <br />
                – matières azotées <br />– matières minérales
              </p>
              <p>
                A la demande, nous pouvons associer des compléments
                oligo-vitaminiques, appelés « Prémix ».
              </p>
            </Col>
            <Col lg={4} className="mx-auto">
                  <img
                    src={require("../../../../assets/production/Production_Site1.jpg")}
                    className="imgProd "
                    alt="production-site-1"
                  />
             
                  <img
                    src={require("../../../../assets/production/Production_Site2.jpg")}
                    className="imgProd mt-4"
                    alt="production-site-2"
                  />
              
                  <img
                    src={require("../../../../assets/production/Production_Site3.jpg")}
                    className="imgProd mt-4"
                    alt="production-site-3"
                  />
                </Col>
          </Row>
        </Container>
      </div>
      <Container className="my-5">
        <Row>
        <Col lg={4} className="mx-auto">
                <img
                  src={require("../../../../assets/production/Production_Camions3.jpg")}
                  className="imgProdTruck mt-4"
                  alt="production-camion-1"
                />
         
                <img
                  src={require("../../../../assets/production/Production_Camions4.jpg")}
                  className="imgProdTruck mt-4"
                  alt="production-camion-2"
                />
              </Col>
          <Col lg={7}>
            <h3 className="subtitle mt-4 mb-3">Logistique</h3>
            <p>
              Nous disposons d’une flotte interne de 4 semi-remorques et 1
              porteur, chacun équipé de citernes compartimentées et de pompe de
              distribution hydraulique.
            </p>
            <p>
              Tous les chargements se font sur pont bascule, avec impression du
              bon de pesée (poids réel). <br />
              Nous disposons également d’une citerne en prestation extérieure,
              équipée d’un volucompteur.
            </p>
            <p>
              La SAS TALIAN distribue ses aliments sur toute la France et le
              Benelux avec ses ensembles routiers, ou par transporteurs
              certifiés Qualimat.
            </p>
                    
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Production;
