import React from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import NavNutri from "../../navNutrition";
import "./nutrition.scss";
const Nutrition = () => {
  return (
    <div className="greenBG">
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - Engraissement" />
        <meta
          property="og:description"
          content="L’utilisation des ACL dans les rations d’engraissement a deux objectifs principaux :"
        />
      </Helmet>

      <NavNutri eventKey="engraissement" />
    
      <Container className="py-5">

        <Row id="laitieres" className="pt-5 align-items-center">
          <Col className="textPart left">
            <h2 className="title mb-5">Les vaches laitières</h2>
            <p className="text fw-bold">
              L’utilisation de l’aliment complémentaire liquide dans les rations des
              vaches laitières est devenu quasiment incontournable, si l’on se
              réfère aux réels besoins de celles-ci, quelque soit la race, le stade
              de lactation et la production de chacune.
            </p>
            <p className="text">
              Toutes nos formules d’aliment liquide sont appropriées pour tout type
              de ration, en fonction des fourrages utilisés, mais aussi, à chaque
              niveau de stade de lactation.
            </p>
            <p className="text">
              Les besoins en énergie des vaches laitières en début de lactation sont
              relativement importants, mais les apports sont souvent limités,
              notamment par une plus faible capacité d’ingestion intervenant après
              vêlage. C’est ici qu’intervient l’intérêt, entre autres, des aliments
              complémentaires liquides, de par leur encombrement nul, apportant
              jusqu’à 1 UFL/Kg de MS et 350 g de sucre/Kg brut sur les 1000g dont la
              vache a besoin quotidiennement.
            </p>
            <p className="text">
              L’aliment complémentaire liquide, apportant sucres et azote soluble,
              de façon simultanée, intervient également sur l’augmentation de
              l’ingestion globale, de l’ordre de 5 à 8 %, de par son appétence, et
              donc par conséquent, l’augmentation de la production laitière, de la
              quantité de Matière Utile produite.
            </p>
            <p className="text">
              N’hésitez pas à contacter votre conseiller terrain <b>Talian</b> ,
              afin de déterminer ensemble la formule d’Aliment Complémentaire
              Liquide adaptée qui correspond à vos besoins, en fonction de votre
              ration et de vos objectifs.
            </p>
          </Col>
          <Col lg={5} className="contCar right">
            <Carousel>
              <Carousel.Item>
                <img
                  className="w-100 carouselVacheLait"
                  src={require("../../../assets/vaches/Nutrition_Laitières_2.jpg")}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="w-100 carouselVacheLait"
                  src={require("../../../assets/vaches/Nutrition_Laitières_4.jpg")}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="w-100 carouselVacheLait"
                  src={require("../../../assets/vaches/Nutrition_Laitières_3.jpg")}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="w-100 carouselVacheLait"
                  src={require("../../../assets/vaches/Home_Vachesnoires.jpg")}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>

        <Row  id="allaitantes" className="pt-5 align-items-center">
          <Col  lg={5} className="contCar left">
            <Carousel>
              <Carousel.Item>
                <img
                  className="w-100 carouselVacheAllait"
                  src={require("../../../assets/vaches/Nutrition_Allaitantes_2.jpg")}
                  alt="image-vache-allaitante"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="w-100 carouselVacheAllait"
                  src={require("../../../assets/vaches/Nutrition_Allaitantes_3.jpg")}
                  alt="image-vache-allaitante"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="w-100 carouselVacheAllait"
                  src={require("../../../assets/vaches/Nutrition_Allaitantes_4.jpg")}
                  alt="image-vache-allaitante"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col className="textPart right">
            <h2 className="title mb-5">Les vaches allaitantes</h2>
            <p className="text fw-bold">
              L’intérêt de l’utilisation des aliments complémentaires liquides dans
              les rations des vaches allaitantes, ainsi que des génisses d’élevage
              est multiple.
            </p>
            <p className="text">
              Encore beaucoup utilisé, à juste titre, comme complément pour faire
              consommer davantage de fibres, notamment dans le cas où le pâturage
              est faible, l’ACL est également conseillé comme apport en protéine
              dans les rations des vaches allaitantes et génisses d’élevage,
              remplaçant tout ou une partie de l’apport azoté. Les formules
              d’aliments proposées s’adaptent aux différentes rations présentes sur
              le territoire national, en ration sèche comme en ration humide, en
              rationné, ou en libre-service (formule adaptée).
            </p>
            <p className="text">
              Dans bon nombre de régions, l’ACL est quasiment l’apport exclusif de
              la matière azotée dans les rations de base des vaches allaitantes et
              génisses d’élevage, déjà correctement pourvues en énergie, non
              déficitaires en PDIE, de par son coût, sa facilité d’utilisation, et
              son appétence, favorisant en plus la consommation de fibres
              grossières.
            </p>
            <p className="text">
              L’utilisation des ACL, dans les rations des vaches allaitantes et
              génisses, est une réelle solution à la fois technique et économique.
              Des formules existent pour chaque type de ration, avec des fourrages
              pouvant varier d’une région à l’autre.
            </p>
          </Col>
        </Row>

        <Row id="engraissement" className="pt-5 align-items-center">
          <Col className="textPart left">
            <h2 className="title mb-5">Engraissement</h2>
            <p className="text fw-bold green">
              L’utilisation des ACL dans les rations d’engraissement a deux
              objectifs principaux :
            </p>
            <p className="text">
              Apporter de l’appétence de façon globale à la ration mélangée
              distribuée, favorisant ainsi l’augmentation de l’ingestion de l’ordre
              de 5 à 8 %.
            </p>
            <p className="text">
              Apporter sucres et azote soluble de façon simultanée, afin d’assurer
              le bon fonctionnement du rumen, tout en favorisant la digestibilité de
              la cellulose.
            </p>
            <p className="text">
              Certaines formules bien spécifiques dans la gamme des aliments liquide
              « Talian » ont pour objectif, avec l’aide d’additifs technologiques
              (prétraitement des fourrages), de contribuer à une meilleure
              digestibilité de la cellulose, afin d’optimiser et valoriser au
              maximum la ration de base.
            </p>
            <p className="text">
              Pour les rations d’engraissement en ration sèche, l’ACL aura les mêmes
              atouts, mais favorisera davantage l’ingestion de fibres, type paille
              de blé, limitant ainsi les désordres métaboliques de type acidose par
              l’aspect mécanique de la fibre consommée dite « efficace ».
            </p>
          </Col>
          <Col lg={5} className="contCar right">
            <Carousel>
              <Carousel.Item>
                <img
                  className="w-100 carouselVacheAllait"
                  src={require("../../../assets/vaches/Home_Jeunes.jpg")}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="w-100 carouselVacheAllait"
                  src={require("../../../assets/vaches/Nutrition_Jeunes_2.jpg")}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="w-100 carouselVacheAllait"
                  src={require("../../../assets/vaches/Nutrition_Jeunes_3a.jpg")}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>


        <Row id="ovins" className="pt-5 align-items-center">
          <Col  lg={5} className="contCar left">
            <Carousel>
              <Carousel.Item>
                <img
                  className="w-100 carouselVacheAllait"
                  src={require("../../../assets/vaches/Nutrition_Ovins1.jpg")}
                  alt="image-ovin"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="w-100 carouselVacheAllait"
                  src={require("../../../assets/vaches/Nutrition_Ovins.jpg")}
                  alt="image-ovin"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="w-100 carouselVacheAllait"
                  src={require("../../../assets/vaches/Nutrition_Ovins2.jpg")}
                  alt="image-ovin"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="w-100 carouselVacheAllait"
                  src={require("../../../assets/vaches/Nutrition_Ovins3.jpg")}
                  alt="image-ovin"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col className="textPart right">
            <h2 className="title mb-5">Les ovins</h2>
            <p className="text fw-bold">
              L’utilisation de l’ACL dans les rations ovines comporte plusieurs
              avantages :
            </p>
            <p className="text">
              Constituer un « liant » pour les rations sèches, permettant ainsi de
              limiter considérablement le tri, mais également éviter la part de
              fines que l’on pourrait trouver dans certains mélanges fermiers,
              notamment ceux à base de céréales.
            </p>
            <p className="text">
              Comme pour les bovins, l’ajout d’ACL sur les fibres (paille ou foin de
              qualité moyenne), permet d’assurer une consommation régulière voire
              plus importante de celles-ci, assurant ainsi un apport simultané de
              sucres et d’azote soluble pour le bon fonctionnement du rumen.
            </p>
            <p className="text">
              L’utilisation des ACL sur les brebis allaitantes ou laitières, peut
              être également une solution économique d’apport de protéines dans
              leurs rations (formules spécifiques).
            </p>
            <p className="text">
              <b>
                Des formules d’ACL adaptées existent pour ce petit ruminant, afin
                d’être au plus proche des besoins spécifiques de ces animaux,
              </b>{" "}
              en fonction des rations(sèche, semi-humide, ou humide)
            </p>
          </Col>
        </Row>

        

        
      </Container>
    </div>
  );
};

export default Nutrition;
