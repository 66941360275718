import React from "react";
import { Form } from "react-bootstrap";

const TextArea = (props) => {
  const { handleChange, label, name, value } = props;
  return (
    <Form.Group className="mb-3">
      <Form.Label htmlFor={name}>{label}</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        onChange={handleChange}
        name={name}
        value={value}
      />
      <Form.Control.Feedback type="invalid">
        Veuillez remplir le champs ci-dessus.
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default TextArea;
