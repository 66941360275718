import React from "react";
import "./carousel.css";
import { Carousel } from "react-bootstrap";

const CarouselHome = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="w-100 imgCarousel"
          src={require("../../../assets/accueil/carousel/Home_Jeunes.jpg")}
          alt="img-carousel-home"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="w-100 imgCarousel"
          src={require("../../../assets/accueil/carousel/Home_ovins.jpg")}
          alt="img-carousel-home"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="w-100 imgCarousel"
          src={require("../../../assets/accueil/carousel/Home_Vachesnoires.jpg")}
          alt="img-carousel-home"
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselHome;
