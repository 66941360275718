import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import './nutrition.scss';

const Nutrition = () => {


  return (
    <div className="mainContainer">
        <Container>
          <h2 className="bgTitle">Les avantages des ACL pour votre élevage</h2>
          <p className="subTitle">Des avantages spécifiques pour vos bovins et ovins de toutes raçes, stades et types de production.</p>
          <Row>
            <Col lg={3} md={6} xs={12}>
            <div className="whiteContainer">
              <div>
                <div  className="imgTitle">
                  <img
                    src={require("../../../assets/vaches/Nutrition_Allaitantes_3.jpg")}
                    className="imgRoundHome"
                    alt="histoire"
                  />
                  <div className="boxTitle"><a href="/nutrition/vaches-allaitantes">Vaches allaitantes</a></div>
                </div>
                <div className="content">L’utilisation des ACL, dans les rations des vaches allaitantes et génisses, est une réelle solution à la fois technique et économique. </div>
              </div>
              <div className="moreBtn">
                <a href="/nutrition#allaitantes" className="btn btn-primary btn-sm">En savoir plus</a>
              </div>
            </div>
            </Col>
            <Col  lg={3} md={6} xs={12}>
            <div className="whiteContainer">
              <div>
              <div className="imgTitle">
                <img
                  src={require("../../../assets/vaches/Home_Vachesnoires.jpg")}
                  className="imgRoundHome"
                  alt="histoire"
                />
                <div className="boxTitle"><a href="/nutrition/vaches-laitieres">Vaches laitières</a></div>
              </div>
              <div className="content">L’utilisation de l’aliment complémentaire liquide dans les rations des vaches laitières est devenu quasiment incontournable, si l’on se réfère aux réels besoins de celles-ci.</div>
              </div>
              <div className="moreBtn">
                <a href="/nutrition#laitieres" className="btn btn-primary btn-sm">En savoir plus</a>
              </div>
            </div>
            </Col>
            <Col  lg={3} md={6} xs={12}>
            <div className="whiteContainer">
              <div>
              <div  className="imgTitle">
              <img
                src={require("../../../assets/vaches/Nutrition_Jeunes_2.jpg")}
                className="imgRoundHome"
                alt="histoire"
              />
                <div className="boxTitle"><a href="/nutrition/engraissement">Engraissement</a></div>
              </div>
              <div className="content">Apporter de l’appétence de façon globale à la ration mélangée distribuée, favorisant ainsi l’augmentation de l’ingestion de l’ordre de 5 à 8 %</div>
              </div>
              <div className="moreBtn">
                <a href="/nutrition#engraissement" className="btn btn-primary btn-sm">En savoir plus</a>
              </div>
            </div>
            </Col>
            <Col  lg={3} md={6} xs={12}>
            <div className="whiteContainer">
              <div>
              <div  className="imgTitle">
              <img
                src={require("../../../assets/vaches/Nutrition_Ovins2.jpg")}
                className="imgRoundHome"
                alt="histoire"
              />
                <div className="boxTitle"><a href="/nutrition/ovins">Ovins</a></div>
              </div>
              <div className="content">Constituer un « liant » pour les rations sèches, permettant ainsi de limiter considérablement le tri, mais également éviter la part de fines que l’on pourrait trouver dans certains mélanges fermiers, notamment ceux à base de céréales.</div>
              </div>
              <div className="moreBtn">
                <a href="/nutrition#ovins" className="btn btn-primary btn-sm">En savoir plus</a>
              </div>
            </div>
            </Col>
          </Row>
        </Container>
    </div>
  );
};

export default Nutrition;
