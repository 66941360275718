import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./qualite.css";
import { Helmet } from "react-helmet";

const Qualite = () => {
  const backgroundWawe = {
    height: "150px",
    backgroundColor: "#00A24F"
  };

  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - qualité" />
        <meta
          property="og:description"
          content="Le sérieux de la société Talian est reconnu au travers de nombreuses certifications qualités acquises depuis des années."
        />
      </Helmet>
      <div className="backgroundGrey py-10">
        <Container>
          <h2 className="title">Qualité</h2>
          <Row className="justify-content-center mt-5">
            <Col lg={6}>
              <div className=" p-3 text-center rounded" style={backgroundWawe}>
                <h3 className="subtitle text-white fw-bold">
                  Sécurité alimentaire
                </h3>
                <p className="text text-white">
                  Nous conseillons vivement le lavage et le nettoyage des cuves
                  d’aliment complémentaire liquide au moins une fois par an.
                  C’est la garantie de vous assurer un produit de qualité
                  constant dans le temps.
                </p>
              </div>

              <div className="d-flex justify-content-center">
                <Col lg={4}>
                  <img
                    src={require("../../../../assets/qualite/Qualité_Autocollant.jpg")}
                    className="imgQualite mt-3"
                    alt="autocollant"
                  />
                </Col>
                <Col lg={4}>
                  <img
                    src={require("../../../../assets/qualite/logoUE_AB.png")}
                    className="imgQualite mt-3"
                    alt="logo"
                  />
                </Col>
                <Col lg={4}>
                  <img
                    src={require("../../../../assets/qualite/Entreprise_Qualite_Logos-2.jpg")}
                    className="imgQualite mt-3"
                    alt="logo"
                  />
                </Col>
              </div>
            </Col>
            <Col lg={6}>
              <img
                src={require("../../../../assets/qualite/Qualite_photos2.jpg")}
                className="imgQualiteRight"
                alt="qualite"
              />
              <p className="text fw-bold mt-3">
                Le sérieux de la société Talian est reconnu au travers de
                nombreuses certifications qualités acquises depuis des années.
              </p>
              <p className="text">
                Soucieuse de la qualité et la traçabilité de ses produits, la
                société TALIAN est certifiée « <b>GMP +</b> » depuis le 27
                janvier 2016, certification basée sur une analyse <b>HACCP</b>{" "}
                maitrisée.
              </p>
              <p className="text">
                Nos fournisseurs de matières premières sont obligatoirement
                certifiés « <b>GMP +</b> », « <b>OQUALIM</b> », ou équivalent.
              </p>
              <p className="text">
                Nos transporteurs externes sont certifiés « <b>QUALIMAT</b> ».
              </p>
              <p className="text">
                Nous sommes certifiés « <b>Oqualim RCNA international STNO</b> »
                depuis le 5 décembre 2017, garantissant l’ensemble de nos
                produits <b>NON-OGM.</b>
              </p>
              <p className="text">
                Nous sommes engagés depuis juillet 2019 dans la démarche «{" "}
                <b>Duralim</b> », afin de promouvoir et d’améliorer la
                durabilité de l’alimentation des animaux d’élevage en France.
              </p>
              <p className="text">
                Toutes nos matières premières sont contrôlées et échantillonnées
                à l’arrivée, ainsi que nos produits fabriqués.
              </p>
              <a
                href="/pdf/certificats/CT707 certificat STNO_TALIAN_V2_2021-2023.pdf"
                className="text-green fw-bold"
                target="_blank"
              >
                Certification STNO
              </a>
              <br />
              <a
                href="/pdf/certificats/CT806 certificat RCNA INTERNATIONAL_TALIAN_V2_2021-2023.pdf"
                className="text-green fw-bold"
                target="_blank"
              >
                Certification RCNA
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="py-5">
        <Row className="text-center">
          <Col lg={6}>
            <img
              src={require("../../../../assets/qualite/Qualité_GMP_Oqualim.jpg")}
              className="imgQualiteEnd mt-3"
              alt="qualite"
            />
            <p className="text-center text mt-4">
              La SAS Talian est certifiée « Oqualim RCNA international STNO »
              sur son nouveau site de production à Crécy sur serre, depuis
              décembre 2017
            </p>
          </Col>
          <Col lg={6}>
            <img
              src={require("../../../../assets/qualite/Qualité_Certificats_3.jpg")}
              className="imgQualiteEnd mt-3"
              alt="certificat"
            />
            <p className="text">
              Les aliments Talian ont reçu de nombreux certificats de qualité :
            </p>
            <ul className="noDot">
              <li className="listQualite">Montbéliarde qualité</li>
              <li className="listQualite">Label rouge charolais LA/11/89</li>
              <li className="listQualite">Label rouge limousin LA/22/88</li>
              <li className="listQualite">
                Label rouge blonde d’aquitaine LA/17/91
              </li>
              <li className="listQualite">Lorraine qualité viande</li>
              <li className="listQualite">Normande viande NV-AQV</li>
              <li className="listQualite">Carrefour</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="backgroundGrey py-5">
        <Container>
          <h3 className="text text-center">
            Ces démarches qualité impliquent plusieurs audits de contrôles
            annuels. <br />
            La SAS Talian participe au plan d’autocontrôles compléments{" "}
            <b>(Oqualim)</b> {/* IL FAUT ADD UN LIEN VERS LA PAGE */}
          </h3>
        </Container>
      </div>
    </>
  );
};

export default Qualite;
