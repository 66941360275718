import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import { Couper } from "../../../../helps/couper";
import Background from "../../../../assets/temoignages/wawe.svg";
import "./temoignage.scss";
import { Helmet } from "react-helmet";

const backgroundWawe = {
  backgroundImage: `url(${Background})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  height: "150px",
};

const Temoignages = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - Témoignages" />
        <meta
          property="og:description"
          content="Découvrez les avis d'éleveurs qui utilisent notre produit"
        />
      </Helmet>

      <Container className="py-5">
        <div className="container-temoignage">
          <h2 className="title text-center">Témoignages</h2>
          <p className="subTitle">
            Découvrez les avis d'éleveurs qui utilisent notre produit
          </p>
          <Row className="justify-content-between">
            <Col lg={4} >
              <div className="card-temoignage d-flex flex-column justify-content-between">
                <span className="backtite p-3">❞</span>
                <p className="text-center text-card p-3 mb-0">
                  Nous utilisons l’aliment complémentaire liquide Protalcanne
                  13/31 pour fabriquer nos mash fibreux fermiers.Nous en mettons
                  à hauteur de 10 % dans notre mélange à base de paille de blé,
                  céréales, pulpe séche, soja / colza, et minéral.Nous
                  reconstituons un aliment complet fibreux, destiné
                  principalement aux jeunes animaux après le sevrage.Nous en
                  préparons dans notre mélangeuse pour 8 à 10 jours d’avance.
                  Avec l’incorporation de l’aliment liquide nous avons diminué
                  fortement le tri, collé les particules fines, et rendu le
                  mélange très appètent.
                </p>
                <p className="strong-text-card text-center mb-0">
                  Gaec Choain, Caucourt (Pas de Calais)
                </p>
                <p className="info-text-card text-center">
                  4,5UTH, Polyculture Elevage, 100 VL Holstein à 9000 kg de
                  lait, 2 robots de traite, 60 vaches allaitantes race mixte.
                </p>
                <div style={backgroundWawe}>
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <Image
                      src={require("../../../../assets/temoignages/Temoignage_1.jpg")}
                      className="imgTemoignage"
                      alt="production-camion-1"
                    />
                    <p className="mt-2 text-white text-center fw-bold">
                      Florent Choain
                    </p>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={4}>
              <div className="card-temoignage d-flex flex-column justify-content-between">
                <span className="backtite p-3">❞</span>
                <p className="text-center text-card p-3 mb-0">
                  Nous utilisons l’aliment complémentaire liquide Protalcanne
                  24/18 dans la ration des vaches laitières depuis plusieurs
                  années. Nous distribuons la ration des vaches laitières deux
                  fois par jour. Nous y incorporons 700g/VL. Nous sommes
                  satisfaits du produit qui est homogène dans le temps, pas de
                  dépôt dans le fond de la cuve, avec une appétence constante.
                  Cette campagne 2016-2017, l’aliment liquide s’est vraiment
                  démarqué, notamment par le manque de sucres dans les rations,
                  avec des fourrages moins appètents . J’ai pu ainsi limiter
                  fortement le tri à l’auge grâce à son effet liant, tout en
                  apportant une source de protéine et du sucre.
                </p>
                <p className="strong-text-card text-center mb-0">
                  Gaec Hennart, Braine (Aisne)
                </p>
                <p className="info-text-card text-center">
                  3 UTH, Polyculture élevage laitier, 140 Ha, 75 Vaches
                  laitières pour 700 000 l de droit à produire.
                </p>
                <div style={backgroundWawe}>
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <Image
                      src={require("../../../../assets/temoignages/Temoignage_2.jpg")}
                      className="imgTemoignage"
                      alt="production-camion-1"
                    />
                    <p className="mt-2 text-white text-center fw-bold">
                      Frédéric Hennart
                    </p>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={4}>
              <div className="card-temoignage d-flex flex-column justify-content-between">
                <span className="backtite p-3">❞</span>
                <p className="text-center text-card p-3 mb-0">
                  Nous utilisons l’aliment complémentaire liquide Protalcanne
                  38/5 depuis plus de 10 ans, dans l’ensemble de nos rations,
                  aussi bien sur les vaches allaitantes que sur les jeunes
                  bovins à l’engraissement, entre 1 kg et 1,5kg par animal et
                  par jour. L’aliment liquide reste une source de protéine à la
                  fois très intéressante d’un point de vue technique mais
                  également d’un point de vue économique. C’est une de nos
                  sources principales en protéine sur l’exploitation, facile
                  d’utilisation, et qui rend en plus beaucoup d’appétence à la
                  ration.
                </p>
                <p className="strong-text-card text-center mb-0">
                  EARL de la Loge Tristan, Chouy (Aisne)
                </p>
                <p className="info-text-card text-center">
                  3 UTH, ferme polyculture élevage allaitant naisseur
                  engraisseur, 120 vaches de race charolaise, 55 jeunes bovins à
                  l’engraissement.
                </p>
                <div style={backgroundWawe}>
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <Image
                      src={require("../../../../assets/temoignages/Temoignage_3.jpg")}
                      className="imgTemoignage"
                      alt="production-camion-1"
                    />
                    <p className="mt-2 text-white text-center fw-bold">
                      Vache allaitante chez Mrs Grymonprez
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Temoignages;
