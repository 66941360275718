import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";

const Teams = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - équipe" />
        <meta
          property="og:description"
          content="L’entreprise Talian compte aujourd’hui 10 collaborateurs travaillant tous pour un seul objectif : vous satisfaire et répondre à vos attentes !"
        />
      </Helmet>

      <Container className="py-10">
        <h2 className="title">Equipe</h2>
        <p className="text mb-5">
          L’entreprise Talian compte aujourd’hui 10 collaborateurs travaillant
          tous pour un seul objectif :{" "}
          <b>vous satisfaire et répondre à vos attentes !</b>
        </p>

        <Row className="justify-content-center">
          <Col lg={3} className="d-flex flex-column align-items-center">
            <img
              src={require("../../../../assets/teams/Equipe_Stephane_C.jpg")}
              className="imgRounded"
              alt="equipe"
            />
            <p className="title text-center mb-1 mt-2">Stéphane Michel</p>
            <p className="text text-center fw-bold">Directeur général</p>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col lg={3} className="d-flex flex-column align-items-center">
            <img
              src={require("../../../../assets/teams/Romain.jpg")}
              className="imgRounded"
              alt="equipe"
            />
            <p className="title text-center mb-1 mt-2">Romain Mulet</p>
            <p className="text text-center fw-bold">
              Responsable technique et commercial Grand Ouest et Nord France
            </p>
          </Col>
           <Col lg={3} className="d-flex flex-column align-items-center">
            <img
              src={require("../../../../assets/teams/Philippe.jpg")}
              className="imgRounded"
              alt="equipe"
            />
            <p className="title text-center mb-1 mt-2">Philippe Guillier</p>
            <p className="text text-center fw-bold">Responsable technique et commercial Grand Est et Centre France</p>
          </Col>
          <Col lg={3} className="d-flex flex-column align-items-center">
            <img
              src={require("../../../../assets/teams/Equipe_JeanYves_C.jpg")}
              className="imgRounded"
              alt="equipe"
            />
            <p className="title text-center mb-1 mt-2">Jean-Yves Lecuyer</p>
            <p className="text text-center fw-bold">
              Responsable de production
            </p>
          </Col>
          <Col lg={3} className="d-flex flex-column align-items-center">
            <img
              src={require("../../../../assets/teams/Gregoire.jpg")}
              className="imgRounded"
              alt="equipe"
            />
            <p className="title text-center mb-1 mt-2">Grégoire Rufin </p>
            <p className="text text-center fw-bold">Responsable Logistique</p>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
        <Col lg={3} className="d-flex flex-column align-items-center">
            <img
              src={require("../../../../assets/teams/Equipe_Charlyne_C.jpg")}
              className="imgRounded"
              alt="equipe"
            />
            <p className="title text-center mb-1 mt-2">Charlyne Traën</p>
            <p className="text text-center fw-bold">Assistante commerciale</p>
          </Col>
          <Col lg={3} className="d-flex flex-column align-items-center">
            <img
              src={require("../../../../assets/teams/Angelique.jpg")}
              className="imgRounded"
              alt="equipe"
            />
            <p className="title text-center mb-1 mt-2">Angélique Meresse</p>
            <p className="text text-center fw-bold">Comptabilité</p>
          </Col>
          <Col lg={3} className="d-flex flex-column align-items-center">
            <img
              src={require("../../../../assets/teams/Equipe_Gaelle_C.jpg")}
              className="imgRounded"
              alt="equipe"
            />
            <p className="title text-center mb-1 mt-2">Gaëlle Potet</p>
            <p className="text text-center fw-bold">Assistante administration des ventes</p>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col lg={3} className="d-flex flex-column align-items-center">
            <img
              src={require("../../../../assets/teams/Equipe_Bruno_C.jpg")}
              className="imgRounded"
              alt="equipe"
            />
            <p className="title text-center mb-1 mt-2">Bruno Poulet</p>
            <p className="text text-center fw-bold">Chauffeur livreur</p>
          </Col>
          <Col lg={3} className="d-flex flex-column align-items-center">
            <img
              src={require("../../../../assets/teams/Equipe_Gérald_C.jpg")}
              className="imgRounded"
              alt="equipe"
            />
            <p className="title text-center mb-1 mt-2">Gérald Gobinet</p>
            <p className="text text-center fw-bold">Chauffeur livreur</p>
          </Col>
          <Col lg={3} className="d-flex flex-column align-items-center">
            <img
              src={require("../../../../assets/teams/Equipe_Mélanie_C.jpg")}
              className="imgRounded"
              alt="equipe"
            />
            <p className="title text-center mb-1 mt-2">Mélanie Antoine</p>
            <p className="text text-center fw-bold">Chauffeur livreur</p>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col lg={3} className="d-flex flex-column align-items-center">
            <img
              src={require("../../../../assets/teams/Equipe_Guy_C.jpg")}
              className="imgRounded"
              alt="equipe"
            />
            <p className="title text-center mb-1 mt-2">Guy Villaire</p>
            <p className="text text-center fw-bold">Chauffeur livreur</p>
          </Col>
          <Col lg={3} className="d-flex flex-column align-items-center">
            <img
              src={require("../../../../assets/teams/Equipe_ChauffeurX.jpg")}
              className="imgRounded"
              alt="equipe"
            />
            <p className="title text-center mb-1 mt-2">
              Chauffeur prestataire occasionnel
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Teams;
