import React from "react";
import { Nav } from "react-bootstrap";
import "./navNut.css";


const NavNutri = (props) => {
  return (
    <Nav variant="pills" className="justify-content-center py-5 colored" defaultActiveKey={props.eventKey}>
        <Nav.Item>
          <Nav.Link href="#laitieres" eventKey="laitieres">Les vaches laitières</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="#allaitantes" eventKey="allaitantes">Les vaches allaitantes</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="#engraissement" eventKey="engraissement">Engraissement</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="#ovins" eventKey="ovins">Les ovins</Nav.Link>
        </Nav.Item>
      </Nav>
  );
};

export default NavNutri;
