import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const Legal = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - Mentions légales" />
        <meta property="og:description" content="Mentions légales" />
      </Helmet>

      <Container className="py-10">
        <h2 className="title mt-5">Mentions légales</h2>
        <p className="mt-5 fw-bold">RESPONSABLE DU CONTENU DU SITE</p>
        <p>
          SA TALIAN, SARL au capital de 61250€, RCS 75B27 <br />
          Siège social : 19 rue Maurice Prévoteau, 51100 Reims
          <br />
          Numéro de TVA intracommunautaire : FR35302135058
          <br />
          Email : talian@sa-talian.fr
          <br />
          Tel : 03 26 07 71 92
          <br />
          www.sa-talian.fr
          <br />
        </p>
        <p className="mt-5 fw-bold">DIRECTEUR DE LA PUBLICATION</p>
        <p>Stéphane Michel, Directeur général.</p>
        <p className="mt-5 fw-bold">PREAMBULE</p>
        <p>
          Les présentes « Mentions légales » déterminent les conditions
          d’utilisation du site, et s’appliquent à tout internaute visitant le
          site.
          <br />
          Les mentions légales peuvent être modifiées à tout moment et sans
          préavis. <br />
          Tout utilisateur est donc invité à les consulter régulièrement.
          <br />
          L’utilisation de ce site implique l’acceptation sans réserve des
          présentes Mentions légales.
        </p>
        <p className="mt-5 fw-bold">CONTENU DU SITE</p>
        <p>
          La société Talian s’efforce d’assurer l’exactitude des informations
          diffusées sur le site.
          <br />
          Elle peut à tout moment modifier et corriger le contenu. Malgré tout
          le soin apporté à la réalisation de ce site et à son actualisation
          régulière, des erreurs peuvent se glisser dans les informations et les
          documents présentés. La société Talian ne pourra être tenue
          responsable en cas d’indisponibilité du site, ou pour n’importe quel
          préjudice causé directement et/ou indirectement, de quelque façon que
          ce soit, du fait de l’utilisation du site.
        </p>
        <p className="mt-5 fw-bold">HEBERGEUR</p>
        <p>
          Le Site www.sa-talian.fr est hébergé par Amazon Web Service dont le
          siège social est situé P.O Box 81226 – Seattle, WA 98108-1226 USA.
        </p>
        <p className="mt-5 fw-bold">CONCEPTION ET REALISATION</p>
        <p>
          Le site www.sa-talian.fr (ci-après « le Site Internet ») est édité par
          AgriViTech(ci-après « la Société »), Société par Actions Simplifiée au
          capital de 757.250 euros, dont le siège social est situé 17-19
          Rond-Point de l’Europe à Bezannes (51430), RCS Reims, numéro SIREN 882
          706 658, représentée par Nicolas Vilmart, en sa qualité de Président,
        </p>
        <p className="mt-5 fw-bold">PROTECTION DE LA VIE PRIVEE </p>
        <p>
          Les informations qui sont collectées sur le www.sa-talian.fr le sont
          en vue de mener à bien la vente en ligne de produits, de gérer des
          données statistiques utiles à la société en interne. Nous nous
          engageons formellement à ne céder en aucune façon ces informations
          pour l’usage de tiers. Conformément à la loi informatique et Libertés
          du 6 janvier 1978, vous disposez d’un droit d’accès, de modification,
          de rectification et de suppression des informations vous concernant.
          Vous pouvez exercer ces droits en vous adressant à SA TALIAN, 19 rue
          Maurice Prévoteau, 51100 Reims ou en écrivant à talian@sa-talian.fr
        </p>
        <p className="mt-5 fw-bold">LIENS</p>
        <p>
          Le site www.sa-talian.fr peut inclure des liens vers d’autres sites
          web ou d’autres sources Internet, qui ne sont pas couverts par les
          présentes Mentions légales.
          <br />
          La société Talian ne pourra être tenue responsable du contenu de ces
          sites. et de l’usage qui pourra en être fait par les utilisateurs.
        </p>
        <p className="mt-5 fw-bold">CREDIT PHOTO</p>
        <p>
          L’ensemble des éléments de ce site (textes, photographies,
          infographies, vidéos, logos) constituent des œuvres au sens du Code de
          la Propriété Intellectuelle. Ces œuvres sont la propriété exclusive de
          Talian. <br />
          Toute représentation ou reproduction, intégrale ou partielle, sans
          l’accord écrit au préalable de l’entreprise est interdite et
          entrainera des procédures judiciaires.
        </p>
      </Container>
    </>
  );
};

export default Legal;
