import React from 'react';
import "./end-section.css";
import { Button } from 'react-bootstrap';
import Background from '../../../assets/test/background.png';
import { Link } from 'react-router-dom';

const EndSection = () => {

  const backgroundWawe = {
    backgroundImage: `url(${Background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "auto",
    color: "white"
  };

  return (
    <div style={backgroundWawe}>
      <div className='d-flex flex-column justify-content-center p-5'>
        <h3 className='mb-4 text-center text-white'>Nouveau service !</h3>
        <p className='fw-bold text-center text-white'>La société TALIAN propose désormais à la demande un suivi technique personnalisé et indépendant, <br />
          en bovin lait et en bovin viande .
        </p>
        <p className='text-center text-white'>Ce service nutrition consiste à vous conseiller, de façon totalement indépendante et objective,
          dans le but de valoriser au maximum les fourrages produits sur votre exploitation.<br />
          Cette démarche nous amène à parler coût de production, rentabilité, efficacité alimentaire et marge,
          en tenant compte de vos objectifs définis au préalable sous forme d’un contrat de suivi.</p>
        <p className='text-center text-white'>N’hésitez pas à nous contacter afin que nous puissions établir ensemble vos besoins en suivi «  expertise nutrition ». </p>
        <div className='mx-auto'>
          <Link to="/contact">
            <Button className="buttonSection mt-3" variant="">Contactez-nous</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default EndSection;
