import React, { useState } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./navbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faSignOut } from "@fortawesome/free-solid-svg-icons";

const NavbarCustom = ({setShowLoginModal, isAdmin, user, setUser}) => {
  const [expanded, setExpanded] = useState(false);

  const logout = () => {
    setUser(null)
    window.location.href = "/"
    localStorage.clear()
  }

  return (
    <>
      <Navbar style={{height: 76}} expanded={expanded} bg="light" expand="lg" className="fixed-top">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                src={require("../../assets/logo/logo-talian.png")}
                className="logo"
                alt="logo-talian"
                width="75"
                height="50"
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle
            onClick={() => setExpanded(expanded ? false : "expanded")}
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <LinkContainer onClick={() => setExpanded(false)} to="/">
                <Nav.Link className="nav-color d-flex flex-column align-items-center justify-content-center">
                  Accueil
                </Nav.Link>
              </LinkContainer>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <NavDropdown
                  className="nav-color"
                  title={
                    <span className="nav-color my-auto">L'entreprise</span>
                  }
                  id="basic-nav-dropdown"
                >
                  <LinkContainer
                    onClick={() => setExpanded(false)}
                    to="/company/history"
                  >
                    <NavDropdown.Item className="nav-color">
                      Histoire
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    onClick={() => setExpanded(false)}
                    to="/company/teams"
                  >
                    <NavDropdown.Item className="nav-color">
                      Equipe
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    onClick={() => setExpanded(false)}
                    to="/company/production"
                  >
                    <NavDropdown.Item className="nav-color">
                      Production et logistique
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    onClick={() => setExpanded(false)}
                    to="/company/matieres"
                  >
                    <NavDropdown.Item className="nav-color">
                      Matières premières
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    onClick={() => setExpanded(false)}
                    to="/company/qualite"
                  >
                    <NavDropdown.Item className="nav-color">
                      Qualité
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    onClick={() => setExpanded(false)}
                    to="/company/services"
                  >
                    <NavDropdown.Item className="nav-color">
                      Services
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              </div>
              <LinkContainer
                onClick={() => setExpanded(false)}
                to="/actualites"
              >
                <Nav.Link href="/actualites" className="nav-color d-flex flex-column align-items-center justify-content-center">
                  Actualités
                </Nav.Link>
              </LinkContainer>
              {/* <LinkContainer
                onClick={() => setExpanded(false)}
                to="/oad"
              >
                <Nav.Link href="/oad" className="nav-color d-flex flex-column align-items-center justify-content-center">
                  OAD
                </Nav.Link>
              </LinkContainer> */}
              
              {/* <div className="d-flex flex-column align-items-center justify-content-center">
                <NavDropdown
                  title={
                    <span className="nav-color my-auto">
                      La nutrition animale
                    </span>
                  }
                  id="basic-nav-dropdown"
                >
                  <LinkContainer
                    onClick={() => setExpanded(false)}
                    to="/nutrition/vaches-laitieres"
                  >
                    <NavDropdown.Item className="nav-color">
                      Les vaches laitières
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    onClick={() => setExpanded(false)}
                    to="/nutrition/vaches-allaitantes"
                  >
                    <NavDropdown.Item className="nav-color">
                      Les vaches allaitantes
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    onClick={() => setExpanded(false)}
                    to="/nutrition/engraissement"
                  >
                    <NavDropdown.Item className="nav-color">
                      Engraissement
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    onClick={() => setExpanded(false)}
                    to="/nutrition/ovins"
                  >
                    <NavDropdown.Item className="nav-color">
                      Les ovins
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              </div> */}
              <div className="d-flex flex-column align-items-center justify-content-center">
                <NavDropdown
                  title={
                    <span className="nav-color my-auto">Nos produits</span>
                  }
                  id="basic-nav-dropdown"
                >
                  <LinkContainer
                    onClick={() => setExpanded(false)}
                    to="/products/protalcanne"
                  >
                    <NavDropdown.Item className="nav-color">
                      Découvrir notre produit Protalcanne
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    onClick={() => setExpanded(false)}
                    to="/products/gamme"
                  >
                    <NavDropdown.Item className="nav-color">
                      Notre gamme
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    onClick={() => setExpanded(false)}
                    to="/products/materiel"
                  >
                    <NavDropdown.Item className="nav-color">
                      Matériel
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    onClick={() => setExpanded(false)}
                    to="/products/temoignages"
                  >
                    <NavDropdown.Item className="nav-color">
                      Vos témoignages
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              </div>
              <LinkContainer
                onClick={() => setExpanded(false)}
                to="/livraisons"
              >
                <Nav.Link className="nav-color d-flex flex-column align-items-center justify-content-center">
                  Livraisons
                </Nav.Link>
              </LinkContainer>
              <LinkContainer onClick={() => setExpanded(false)} to="/FAQ">
                <Nav.Link className="nav-color d-flex flex-column align-items-center justify-content-center">
                  Questions fréquentes
                </Nav.Link>
              </LinkContainer>
              <LinkContainer onClick={() => setExpanded(false)} to="/contact">
                <Nav.Link className="nav-color d-flex flex-column align-items-center justify-content-center">
                  Contact
                </Nav.Link>
              </LinkContainer>
              {user ?
                (isAdmin && (
                  <LinkContainer to="/admin">
                    <Nav.Link className="nav-color d-flex flex-column align-items-center justify-content-center">
                    Administration
                    </Nav.Link>
                  </LinkContainer>
                ))
                :
                <Nav.Link onClick={() => setShowLoginModal(true)} className="nav-color d-flex  align-items-center space-between">
                  <FontAwesomeIcon icon={faLock} style={{height:10, color:"grey", marginRight:5}} /> Connexion
                </Nav.Link>
              }
              {user && (
                <Nav.Link onClick={logout} className="nav-color d-flex flex-column align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faSignOut} />
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarCustom;
