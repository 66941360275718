import React, { useEffect, useState } from 'react'
import "./menu.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faUser,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import parseJwt from '../../../../helps/parseJwt';
import { loadFromLocalStorage } from '../../../../helps/localStorage';

export default function MenuAdmin({currentPage, setCurrentPage}) {
  const [parsedToken, setParsedToken] = useState(null)

  const token = loadFromLocalStorage("token")
  
  useEffect(() => {
    (async() => {
      if(token){
        let decodedToken = await parseJwt(token)
        setParsedToken(decodedToken)
      }
    })()
  }, [token])
  
  return (
    <div className="menu-container">
      <div style={{display: "flex", marginTop: 30}}>
        <div
          style={{display: "flex", justifyContent: "center", alignItems: "center", width: 50, height: 50, borderRadius: 25, backgroundColor: "#f4f4f4", marginRight: 10}}
        >
          <FontAwesomeIcon
            icon={faUser}
            color={"#00A24F"}
            style={{fontSize: 16}}
          />
        </div>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
          <div style={{fontWeight: 600, fontFamily: "Montserrat"}}>
            {parsedToken?.firstname}
          </div>
          <div style={{fontWeight: 400, fontFamily: "Montserrat"}}>
            Administrateur
          </div>
        </div>
      </div>
      <div style={{marginTop: 30}}>
        <div style={{fontSize: 12, fontWeight: 600, fontFamily: "Montserrat"}}>
          Menu
        </div>

        <div onClick={() => setCurrentPage("users")} style={{display: "flex", alignItems: "center", marginTop: 20, cursor: "pointer"}}>
          {currentPage === "users" && (
            <div className='left-pipe' />
          )}
          <FontAwesomeIcon
            icon={faUsers}
            style={{ marginRight: 10, color: currentPage === "users" ? "#00A24F" : "#151515" }}
          />
          <div style={{color: currentPage === "users" ? "#00A24F" : "#151515", fontWeight: currentPage === "users" ? 600 : 400, fontFamily: "Montserrat"}}>
            Utilisateurs
          </div>
        </div>

        <div  className='disabled' style={{display: "flex", alignItems: "center", marginTop: 20, cursor: "not-allowed"}}>
          {currentPage === "products" && (
            <div className='left-pipe' />
          )}
          <FontAwesomeIcon
            icon={faFile}
            style={{ marginRight: 10, color: currentPage === "products" ? "#00A24F" : "#151515" }}
          />
          <div style={{color: currentPage === "products" ? "#00A24F" : "#151515", fontFamily: "Montserrat", fontWeight: currentPage === "products" ? 600 : 400}}>
            Produits
          </div>
        </div>
      </div>
    </div>
  )
}
