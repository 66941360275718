import React from "react";
import { Container, Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet";

const FAQ = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - Questions fréquentes" />
        <meta
          property="og:description"
          content="Découvrez nos réponses aux questions fréquemment posées."
        />
      </Helmet>

      <Container className="py-10">
        <h2 className="title">Questions fréquentes</h2>
        <p className="text mb-5">
          Découvrez nos réponses aux questions fréquemment posées.
        </p>
        <h3 className="subtitle">Nos produits</h3>

        <Accordion defaultActiveKey="0" className="mt-5">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Je souhaite principalement améliorer l’appétence de ma ration
              mélangée (base ensilage de maïs et pulpes surpressées) et
              également diminuer le tri à l’auge, quelle est la formule adaptée,
              sachant que le niveau azoté de ma ration est déjà bon ?
            </Accordion.Header>
            <Accordion.Body className="text">
              Dans votre cas, les formules adaptées seraient plutôt du type «
              ACL 13/31 », voire « ACL 24/18 », afin d’apporter les sucres
              nécessaires, par conséquent rendre de l’appétence, et par l’effet
              liant, diminuer le phénomène de tri à l’auge.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Quelle est la densité de votre aliment liquide ?
            </Accordion.Header>
            <Accordion.Body className="text">
              La densité de notre aliment liquide est de l’ordre de 1,24. Litres
              / Kilos
              <br />
              1000 / 1240
              <br />
              1500 / 1860
              <br />
              2000 / 2480
              <br />
              2500 / 3100
              <br />
              3000 / 3720
              <br />
              3500 / 4340
              <br />
              4000 / 4960
              <br />
              4500 / 5580
              <br />
              5000 / 6200
              <br />
              5500 / 6820
              <br />
              6000 / 7440
              <br />
              7000 / 8680 <br />
              8000 / 9920
              <br />
              9000 / 11160
              <br />
              10000 / 12400
              <br />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Quelle quantité et quelle formule d’aliment liquide dois-je mettre
              sur ma boule de paille pour optimiser l’ingestion ?
            </Accordion.Header>
            <Accordion.Body className="text">
              La quantité d’aliment liquide à déverser est de l’ordre de 10 à 15
              % du poids d’une boule ou cubique. Par exemple, sur une boule de
              300kg, on déversera entre 30 et 45 kg (soit entre 25l et 36l)
              d’une formule type « Protalcanne 24/18 » ou « Protalcanne 30/12.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Vos aliments liquides contiennent ils de l’urée alimentaire ?
            </Accordion.Header>
            <Accordion.Body className="text">
              Certaines formules d’ACL contiennent effectivement de l’urée
              alimentaire, pouvant aller de 1% à 10% d’urée en formulation, en
              tenant compte du cahier des charges. Néanmoins, nous avons une
              gamme de produits allant de 10% de protéines à plus de 38 %
              également sans urée.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Quelle type de mélasse utilisez-vous ?
            </Accordion.Header>
            <Accordion.Body className="text">
              Nous travaillons avec de la mélasse de betteraves sucrières,
              mélasse de canne à sucre, et mélasse du glucose.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <h3 className="subtitle my-5">Livraison</h3>

        <Accordion>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              Vendez vous des cuves pouvant recevoir de l’ACL ?
            </Accordion.Header>
            <Accordion.Body className="text">
              Oui, nous vendons des cuves grillagées de 1000l plastiques, avec
              vanne et coude d’alimentation. Nous pouvons vous livrer jusqu’à
              deux cuves à la fois.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>
              Quelle quantité minimum pouvez-vous me livrer en ACL ?
            </Accordion.Header>
            <Accordion.Body className="text">
              Sur les régions proches du site de fabrication, nous livrons à
              partir de 1500l d’ACL. Pour les autres régions du Centre et du sud
              de la France, nous contacter.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header>
              Vous livrez les ACL à la tonne ou au litre ?
            </Accordion.Header>
            <Accordion.Body className="text">
              Nous prenons les commandes en litres, mais nous livrons en tonnes,
              avec un bon de pesée, ou en volucompteur avec une densité en face
              de chaque produit.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
};

export default FAQ;
