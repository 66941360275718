import React from "react";
import { Container, Carousel } from "react-bootstrap";
import { Helmet } from "react-helmet";
import NavNutri from "../../../navNutrition";

const Ovins = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - ovins" />
        <meta
          property="og:description"
          content="L’utilisation de l’ACL dans les rations ovines comporte plusieurs avantages :"
        />
      </Helmet>
      <Carousel>
        <Carousel.Item>
          <img
            className="w-100 carouselVacheAllait"
            src={require("../../../../assets/vaches/Nutrition_Ovins1.jpg")}
            alt="image-ovin"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100 carouselVacheAllait"
            src={require("../../../../assets/vaches/Nutrition_Ovins.jpg")}
            alt="image-ovin"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100 carouselVacheAllait"
            src={require("../../../../assets/vaches/Nutrition_Ovins2.jpg")}
            alt="image-ovin"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100 carouselVacheAllait"
            src={require("../../../../assets/vaches/Nutrition_Ovins3.jpg")}
            alt="image-ovin"
          />
        </Carousel.Item>
      </Carousel>
      <Container className="py-5">

      <NavNutri eventKey="ovins" />


        <h2 className="title mb-5">Les ovins</h2>
        <p className="text fw-bold">
          L’utilisation de l’ACL dans les rations ovines comporte plusieurs
          avantages :
        </p>
        <p className="text">
          Constituer un « liant » pour les rations sèches, permettant ainsi de
          limiter considérablement le tri, mais également éviter la part de
          fines que l’on pourrait trouver dans certains mélanges fermiers,
          notamment ceux à base de céréales.
        </p>
        <p className="text">
          Comme pour les bovins, l’ajout d’ACL sur les fibres (paille ou foin de
          qualité moyenne), permet d’assurer une consommation régulière voire
          plus importante de celles-ci, assurant ainsi un apport simultané de
          sucres et d’azote soluble pour le bon fonctionnement du rumen.
        </p>
        <p className="text">
          L’utilisation des ACL sur les brebis allaitantes ou laitières, peut
          être également une solution économique d’apport de protéines dans
          leurs rations (formules spécifiques).
        </p>
        <p className="text">
          <b>
            Des formules d’ACL adaptées existent pour ce petit ruminant, afin
            d’être au plus proche des besoins spécifiques de ces animaux,
          </b>{" "}
          en fonction des rations(sèche, semi-humide, ou humide)
        </p>
      </Container>
    </>
  );
};

export default Ovins;
