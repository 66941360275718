import React from "react";
import Header from "../../home/header";
import Nutrition from "../../home/nutrition";
import Actu from "../../home/actu";
import EndSection from "../../home/end-section";
import Temoignages from "../../pages/products/temoignage"
import { Helmet } from "react-helmet";

const Accueil = () => {
  return (
    <div className="padding-accueil">
      <Helmet>
        ‍<title>Talian - Accueil</title>‍
        <meta name="description" content="Accueil du site web Talian" />
        <meta name="twitter:title" content="Talian - Accueil" />
        <meta
          name="twitter:description"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta
          name="twitter:image"
          content="https://staging.talian.agrivitech.com/static/media/Home_Jeunes.1414dba098e43c405842.jpg"
        />
        <meta property="og:title" content="Talian - Accueil" />
        <meta
          property="og:description"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta
          property="og:image"
          content="https://staging.talian.agrivitech.com/static/media/Home_Jeunes.1414dba098e43c405842.jpg"
        />
        
      </Helmet>
      <Header />
      <Nutrition />
      <Temoignages/>
      
      <Actu />
      
      <EndSection />
    </div>
  );
};

export default Accueil;
