import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import styles from "./matieres.scss"

const Matieres = () => {
  return (
    <>
      <Helmet>
        {/* Placez ici vos méta données */}
        <title>Talian</title>
        <meta
          name="Talian"
          content="Nutrition Animale Fabricant d’Aliment Complémentaire Liquide"
        />
        <meta property="og:title" content="Talian - Matières premières" />
        <meta
          property="og:description"
          content="Les Aliments Complémentaires Liquides sont constitués de plusieurs matières premières liquides non OGM."
        />
      </Helmet>

      <Container fluid className="mainContainer">
        <Container className="p-5">
        <h2 className="title">Matières premières</h2>
        <p className="text mb-5">
          Les Aliments Complémentaires Liquides sont constitués de plusieurs
          matières premières liquides non OGM.
        </p>
    
            <div className="cardStd mx-auto">
              <h3 className="title"> <span className="icon">MC</span> Mélasse de canne</h3>
              <p className="text">
                Co-produit de l’extraction de sucre (glucose et saccharose) à
                partir de canne à sucre, liquide sirupeux composé de <strong>45% de sucre,
                30% d’eau et 14% de matière minérale</strong>.
              </p>
            </div>
    
            <div className="cardStd mx-auto">
              <h3 className="title"> <span className="icon">GL</span> Glycérol</h3>
              <p className="text">
                Le Glycérol est un coproduit de la fabrication industrielle de
                biodiésel, à partir d’oléagineux comme le colza et le tournesol,
                mais aussi de la production de savon. Le Glycérol, appelé
                également « Glycérine », est surtout apprécié pour <strong>sa valeur
                énergétique, atteignant 1,35UFL/kg de MS</strong>.
              </p>
            </div>
       
  
            <div className="cardStd mx-auto">
              <h3 className="title"> <span className="icon">SL</span> Solubles de levurerie</h3>
              <p className="text">
                Co-produit obtenu après fermentation de mélasse par des bactéries
                ou des levures pour obtenir des levures boulangères, des acides
                aminés. Ces solubles sont la <strong>principale source de protéines dans
                la composition des aliments</strong> complémentaires liquides.
              </p>
            </div>
    
            <div className="cardStd mx-auto">
              <h3 className="title"> <span className="icon">MG</span> Mélasse de glucose</h3>
              <p className="text">
                Issu du raffinage d’amidon hydrolysé et dextrinifié. Riche en
                sucre à hauteur de 40%, riche en énergie.
              </p>
            </div>
  
            <div className="cardStd mx-auto">
              <h3 className="title"> <span className="icon">MB</span> Mélasse de betterave</h3>
              <p className="text">
                Co-produit de l’extraction de sucre (saccharose essentiellement) à
                partir de la betterave sucrière. Liquide sirupeux composé de 47%
                de sucre, 30% d’eau et 13 % de matière minérale.
              </p>
            </div>
        </Container>
      </Container>
    </>
  );
};

export default Matieres;
