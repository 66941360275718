import React, { useState } from "react"
import Footer from "./footer"
import LoginModal from "./login/modal";
import NavbarCustom from "./navbar";

const Layout = ({children, user, setUser, isAdmin}) => {
  const [showLoginModal, setShowLoginModal] = useState(false)

  return (
    <>
      <NavbarCustom isAdmin={isAdmin} user={user} setUser={setUser} setShowLoginModal={setShowLoginModal} />
      <main className="app_root">{children}</main>
      <LoginModal setUser={setUser} show={showLoginModal} setShow={setShowLoginModal} />
      <Footer />
    </>
  )
}
export default Layout;